export const renderDropdownTitle = (data, addonsObj) => {
  const newTemp = data?.optionValues?.map((rl) => {
    const rest = addonsObj?.attributes?.find(
      (ad) => ad?.options_values_id == rl?.options_values_id
    );
    if (rest) {
      return rl;
    }
  });
  const test = newTemp?.find((dtt) => dtt !== undefined);
  if (test) {
    return (
      <>
        <span>{test?.products_options_values_name}</span>

        {test?.options_values_price &&
          parseFloat(test?.options_values_price)?.toFixed(2) > 0 && (
            <span>${parseFloat(test?.options_values_price)?.toFixed(2)}</span>
          )}
      </>
    );
  } else {
    return (
      <>
        <span>Select</span>
      </>
    );
  }
};

export const renderCheckboxRequiredOpt = (data) => {
  if (data?.attributes_min_select == 1 && data?.attributes_max_select == 1) {
    return "Required";
  } else if (
    data?.attributes_min_select == 0 &&
    data?.attributes_max_select >= 1
  ) {
    return `Choose up to ${data?.attributes_max_select}`;
  } else if (
    data?.attributes_min_select > 0 &&
    data?.attributes_max_select >= 1
  ) {
    return `You can select minimum ${data?.attributes_min_select} and maximum ${data.attributes_max_select}`;
  }
};

export const renderDropdownTitleSecondLevel = (
  firstLevelOptionValue,
  secondLevelOption,
  addonsObj
) => {
  const newTemp = addonsObj?.attributes?.find(
    (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
  );
  const newPayLoad = secondLevelOption?.optionValues?.map((rl) => {
    const rest = newTemp?.second_level_attributes?.find(
      (ad) => ad?.options_values_id == rl?.options_values_id
    );
    if (rest) {
      return rl;
    }
  });
  const test = newPayLoad?.find((dtt) => dtt !== undefined);
  if (test) {
    return (
      <>
        <span>{test?.products_options_values_name}</span>
        {parseFloat(test?.options_values_price)?.toFixed(2) > 0 && (
          <span>${parseFloat(test?.options_values_price)?.toFixed(2)}</span>
        )}
      </>
    );
  } else {
    return (
      <>
        <span>Select</span>
      </>
    );
  }
};

export const renderFirstLevelAndSecondLevel = (item, restaurants) => {
  const arr = [];
  item?.first_level_attributes?.map((el) => {
    if (el?.products_options_value_text) {
      arr.push(el?.products_options_value_text);
    } else {
      arr.push(
        restaurants?.is_display_modifier_quantity === 1
          ? `${el?.quantity} x ${el?.products_options_values_name}`
          : el?.products_options_values_name
      );
      if (el?.second_level_attributes?.length > 0) {
        el?.second_level_attributes?.map((ey) => {
          if (ey?.products_options_value_text) {
            arr.push(ey?.products_options_value_text);
          } else {
            arr.push(
              restaurants?.is_display_modifier_quantity === 1
                ? `${ey?.quantity} x ${ey?.products_options_values_name}`
                : ey?.products_options_values_name
            );
            if (ey?.third_level_attributes?.length > 0) {
              ey?.third_level_attributes?.map((dy) => {
                if (dy?.products_options_value_text) {
                  arr.push(dy?.products_options_value_text);
                } else {
                  arr.push(
                    restaurants?.is_display_modifier_quantity === 1
                      ? `${dy?.quantity} x ${dy?.products_options_values_name}`
                      : dy?.products_options_values_name
                  );
                  if (dy?.fourth_level_attributes?.length > 0) {
                    dy?.fourth_level_attributes?.map((it) => {
                      if (it?.products_options_value_text) {
                        arr.push(it?.products_options_value_text);
                      } else {
                        arr.push(
                          restaurants?.is_display_modifier_quantity === 1
                            ? `${it?.quantity} x ${it?.products_options_values_name}`
                            : it?.products_options_values_name
                        );
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    }
  });

  const resultFirstLevel = arr.join(", ").replace(/,\s*$/, "");
  return resultFirstLevel;
};

export const renderAllLevelMods = (item) => {
  const arr = [];
  item?.parent_attribute?.map((el) => {
    arr.push(el?.products_options_values);
    if (el?.child_attribute?.length > 0) {
      el?.child_attribute?.map((ey) => {
        arr.push(ey?.products_options_values);
        if (ey?.third_level_attribute?.length > 0) {
          ey?.third_level_attribute?.map((dy) => {
            arr.push(dy?.products_options_values);
            if (dy?.fourth_level_attribute?.length > 0) {
              dy?.fourth_level_attribute?.map((it) => {
                arr.push(it?.products_options_values);
              });
            }
          });
        }
      });
    }
  });

  const resultFirstLevel = arr.join(", ").replace(/,\s*$/, "");
  return resultFirstLevel;
};

export const renderDropdownTitleThirdLevel = (
  SecondLevel,
  ThirdLevel,
  ThirdLevelOption,
  addonsObj
) => {
  const firstIndex = addonsObj?.attributes?.findIndex(
    (ty) => ty?.options_values_id == SecondLevel?.options_values_id
  );
  const newSecondIndex = addonsObj?.attributes?.[
    firstIndex
  ]?.second_level_attributes?.findIndex(
    (item) => item.options_values_id === ThirdLevel?.options_values_id
  );
  const newTemp = addonsObj?.attributes[firstIndex]?.second_level_attributes[
    newSecondIndex
  ]?.third_level_attributes.find(
    (it) => it?.options_id === ThirdLevelOption?.options_id
  );

  if (newTemp) {
    return (
      <>
        <span>{newTemp?.products_options_values_name}</span>
        {parseFloat(newTemp?.options_values_price)?.toFixed(2) > 0 && (
          <span>${parseFloat(newTemp?.options_values_price)?.toFixed(2)}</span>
        )}
      </>
    );
  } else {
    return (
      <>
        <span>Select</span>
      </>
    );
  }
};

export const renderDropdownTitleFourthLevel = (
  SecondLevel,
  ThirdLevel,
  FourthLevel,
  fourthLevelOption,
  addonsObj
) => {
  const firstIndex = addonsObj?.attributes?.findIndex(
    (ty) => ty?.options_values_id == SecondLevel?.options_values_id
  );
  const newSecondIndex = addonsObj?.attributes?.[
    firstIndex
  ]?.second_level_attributes?.findIndex(
    (item) => item.options_values_id === ThirdLevel?.options_values_id
  );
  const newThirdIndex = addonsObj?.attributes?.[
    firstIndex
  ]?.second_level_attributes?.[
    newSecondIndex
  ]?.third_level_attributes?.findIndex(
    (item) => item.options_values_id === FourthLevel?.options_values_id
  );
  const newTemp = addonsObj?.attributes[firstIndex]?.second_level_attributes[
    newSecondIndex
  ]?.third_level_attributes?.[newThirdIndex]?.fourth_level_attributes.find(
    (it) => it?.options_id === fourthLevelOption?.options_id
  );

  if (newTemp) {
    return (
      <>
        <span>{newTemp?.products_options_values_name}</span>
        {parseFloat(newTemp?.options_values_price)?.toFixed(2) > 0 && (
          <span>${parseFloat(newTemp?.options_values_price)?.toFixed(2)}</span>
        )}
      </>
    );
  } else {
    return (
      <>
        <span>Select</span>
      </>
    );
  }
};

export const checkAndReturnTotalPrice = (
  attribute,
  optionsIds,
  oOuterOptionPrice
) => {
  let outerOptionPrice = 0,
    totalPrice = 0;
  if (attribute?.free_modifier_quantity) {
    const index = optionsIds.findIndex(
      (o) =>
        o.optionId_Price ===
        `${attribute.options_id}#${attribute?.options_values_price}`
    );
    if (index !== -1) {
      optionsIds[index].quantity += attribute?.quantity;
    } else {
      optionsIds.push({
        options_id: attribute.options_id,
        quantity: attribute?.quantity,
        optionId_Price: `${attribute.options_id}#${attribute?.options_values_price}`,
      });
    }
    const isMin =
      Math.min(
        ...attribute?.optionValues1?.map((item) =>
          parseFloat(item.options_values_price)
        )
      ) === parseFloat(attribute.options_values_price);
    if (isMin) {
      let optionIndex = optionsIds.findIndex(
        (o) =>
          o.optionId_Price ===
          `${attribute.options_id}#${attribute?.options_values_price}`
      );
      let netQty =
        optionsIds[optionIndex]?.quantity - attribute?.free_modifier_quantity;
      netQty = netQty > 0 ? netQty : 0;
      optionsIds[optionIndex].quantity -= netQty;
      outerOptionPrice = parseFloat(attribute?.options_values_price) * netQty;
    } else {
      totalPrice +=
        parseFloat(attribute?.options_values_price) * attribute?.quantity;
    }
  } else {
    totalPrice +=
      parseFloat(attribute?.options_values_price) * attribute?.quantity;
  }
  return {
    price: totalPrice,
    outerPrice: oOuterOptionPrice || outerOptionPrice,
  };
};

export const removeDuplicates = (arr) => [...new Set(arr)];

export const responseReturnFunction = (dt, isOptionValue = true) => {
  return {
    options_id: dt?.options_id,
    options_values_id: dt?.options_values_id,
    ...(isOptionValue && { options_value_text: dt?.options_value_text ?? "" }),
    quantity: dt?.quantity,
    free_modifier_quantity: dt?.free_modifier_quantity,
  };
};
