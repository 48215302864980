// // googleMapsService.js
// import React, { createContext, useContext, useState, useEffect } from "react";
// import { useJsApiLoader } from "@react-google-maps/api";

// // Define your two API keys
// const TRACKING_API_KEY = "AIzaSyCJOYrk0ia3CDwLnlPvUV6-LGPk9PcsiOw"; // For OrderTrackNew
// const GENERAL_API_KEY = "AIzaSyBF7EziYJgD2D2V6yp6FERDWWfuHIu6WPk"; // For other components

// // Create a context to manage the active API configuration
// const GoogleMapsContext = createContext(null);

// export const GoogleMapsProvider = ({ children }) => {
//   // Track which API key is currently active
//   const [activeApiKey, setActiveApiKey] = useState(null);
//   const [activeConfig, setActiveConfig] = useState(null);
//   const [scriptUnloaded, setScriptUnloaded] = useState(true);

//   // Function to load a specific configuration
//   const loadApiConfiguration = (configType) => {
//     // If the same configuration is already active, do nothing
//     if (configType === activeApiKey) {
//       return;
//     }

//     // If a different configuration is active, we need to unload it first
//     if (activeApiKey && activeApiKey !== configType) {
//       // Find the script tag
//       const script = document.getElementById("google-map-script");
//       if (script) {
//         // Remove the script
//         script.parentNode.removeChild(script);
//         // Reset global state that might have been set by the Maps API
//         window.google = undefined;
//         setScriptUnloaded(true);
//       }
//     }

//     // Set the new active configuration
//     setActiveApiKey(configType);

//     console.log("configType", configType);

//     // Determine the configuration to use
//     if (configType == "tracking") {
//       setActiveConfig({
//         id: "google-map-script",
//         googleMapsApiKey: TRACKING_API_KEY,
//         version: "3.47",
//         libraries: ["geometry", "places", "roads"],
//       });
//     } else {
//       setActiveConfig({
//         id: "google-map-script",
//         googleMapsApiKey: GENERAL_API_KEY,
//         version: "3.47",
//         libraries: ["maps"],
//       });
//     }
//   };

//   // Context value
//   const contextValue = {
//     activeApiKey,
//     loadApiConfiguration,
//     scriptUnloaded,
//   };

//   return (
//     <GoogleMapsContext.Provider value={contextValue}>
//       {activeConfig && scriptUnloaded && (
//         <GoogleMapsLoader
//           config={activeConfig}
//           onLoad={() => setScriptUnloaded(false)}
//         />
//       )}
//       {children}
//     </GoogleMapsContext.Provider>
//   );
// };

// // Component to handle the actual loading of the script
// const GoogleMapsLoader = ({ config, onLoad }) => {
//   const { isLoaded, loadError } = useJsApiLoader(config);

//   useEffect(() => {
//     if (isLoaded) {
//       onLoad();
//     }
//   }, [isLoaded, onLoad]);

//   return null;
// };

// // Custom hook to use in components
// export const useGoogleMaps = (configType = "general") => {
//   const context = useContext(GoogleMapsContext);

//   if (!context) {
//     throw new Error("useGoogleMaps must be used within a GoogleMapsProvider");
//   }

//   useEffect(() => {
//     context.loadApiConfiguration(configType);
//   }, [configType, context]);

//   return {
//     isLoaded: !context.scriptUnloaded && context.activeApiKey === configType,
//     loadError: false,
//     configType,
//   };
// };

// googleMapsService.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "services/path";

// Create a context to manage the API configuration
const GoogleMapsContext = createContext(null);

export const GoogleMapsProvider = ({ children }) => {
  const [scriptUnloaded, setScriptUnloaded] = useState(true);

  // API configuration
  const config = {
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    version: "3.47",
    libraries: ["places", "geometry", "roads", "maps"],
  };

  return (
    <GoogleMapsContext.Provider value={{ scriptUnloaded }}>
      {scriptUnloaded && (
        <GoogleMapsLoader
          config={config}
          onLoad={() => setScriptUnloaded(false)}
        />
      )}
      {children}
    </GoogleMapsContext.Provider>
  );
};

const GoogleMapsLoader = ({ config, onLoad }) => {
  const { isLoaded } = useJsApiLoader(config);

  useEffect(() => {
    if (isLoaded) {
      onLoad();
    }
  }, [isLoaded, onLoad]);

  return null;
};

export const useGoogleMaps = () => {
  const context = useContext(GoogleMapsContext);

  if (!context) {
    throw new Error("useGoogleMaps must be used within a GoogleMapsProvider");
  }

  return {
    isLoaded: !context.scriptUnloaded,
    loadError: false,
  };
};
