import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Fragment, useRef } from "react";
import {
  checkAndReturnTotalPrice,
  removeDuplicates,
  renderCheckboxRequiredOpt,
  renderDropdownTitleThirdLevel,
} from "helpers/AddonsModal/AddonsModalHocFunctions";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { errorToast } from "utils/toastMessage";
import { GET_SAVE_CART_LOG } from "store/Action/RasturantMenuAction";
import { useDispatch } from "react-redux";

const ThirdLevelBlock = ({
  setVisibleThirdLevelBlock,
  secondLevelData,
  thirdLevelData,
  addonsObj,
  setAddonsObj,
  setTemp,
  temp,
  ADDONS_DETAILS,
  setErrorHandler,
  errorHandlerArr,
  RESTAURANTS,
  handelShowFourthLevelModiefire,
  showFourthLevelBlockUi,
  showFourthLevelBlock,
  setShowFourthLevelBlock,
  activeFourthLevelBlock,
  setActiveNestedMode,
}) => {
  const dispatch = useDispatch();
  const divRefs = useRef([]);

  const handelChangeCheckboxThirdLevel = (
    event,
    secondLevel,
    thirdLevel,
    thirdLevelOptionValue
  ) => {
    const result = {
      options_id: thirdLevel?.options_id,
      options_values_id: thirdLevelOptionValue?.options_values_id,
      options_values_price: thirdLevelOptionValue?.options_values_price,
      // options_value_text: "",
      attributes_max_select: thirdLevel?.attributes_max_select,
      attributes_min_select: thirdLevel?.attributes_min_select,
      products_options_name: thirdLevel?.products_options_name,
      products_options_values_name:
        thirdLevelOptionValue?.products_options_values_name,
      quantity: thirdLevel?.increment_choice_quantity,
      optionValues1: thirdLevel?.optionValues,
      free_modifier_quantity: thirdLevel?.free_modifier_quantity,
      attributes_default: thirdLevelOptionValue?.attributes_default,
      fourth_level_attributes: [],
    };
    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == secondLevel?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) => item.options_values_id === thirdLevelData?.options_values_id
    );
    if (event) {
      if (newSecondIndex !== -1)
        addonsObj?.attributes[firstIndex].second_level_attributes[
          newSecondIndex
        ].third_level_attributes.push(result);
      setAddonsObj(addonsObj);
      handelShowFourthLevelModiefire(
        thirdLevel,
        thirdLevelOptionValue,
        "check",
        true
      );
      setTemp(!temp);
    } else {
      addonsObj.attributes[firstIndex].second_level_attributes[
        newSecondIndex
      ].third_level_attributes = addonsObj?.attributes[
        firstIndex
      ].second_level_attributes[newSecondIndex].third_level_attributes.filter(
        (el) =>
          el?.options_values_id !== thirdLevelOptionValue.options_values_id
      );
      setShowFourthLevelBlock(false);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeRadioThirdLevel = (secondLevel, event, thirdLevel) => {
    const thirdLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: thirdLevel?.options_id,
      options_values_id: thirdLevelOptionValue?.options_values_id,
      options_values_price: thirdLevelOptionValue?.options_values_price,
      attributes_max_select: thirdLevel?.attributes_max_select,
      attributes_min_select: thirdLevel?.attributes_min_select,
      products_options_name: thirdLevel?.products_options_name,
      products_options_values_name:
        thirdLevelOptionValue?.products_options_values_name,
      quantity: thirdLevelOptionValue?.quantity,
      optionValues1: thirdLevel?.optionValues,
      free_modifier_quantity: thirdLevel?.free_modifier_quantity,
      attributes_default: thirdLevelOptionValue?.attributes_default,
      fourth_level_attributes: [],
    };

    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == secondLevel?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) => item.options_values_id === thirdLevelData?.options_values_id
    );
    const newTemp =
      addonsObj?.attributes[firstIndex]?.second_level_attributes[newSecondIndex]
        ?.third_level_attributes;
    if (newTemp?.some((dl) => dl?.options_id == thirdLevel?.options_id)) {
      const pal = newTemp?.filter(
        (el) => el?.options_id !== thirdLevel?.options_id
      );
      pal.push(result);
      addonsObj.attributes[firstIndex].second_level_attributes[
        newSecondIndex
      ].third_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
    handelShowFourthLevelModiefire(
      thirdLevel,
      thirdLevelOptionValue,
      "radioType",
      true
    );
  };

  const handelChangeDropdownThirdLevel = (
    secondLevel,
    event,
    thirdLevelOption
  ) => {
    const thirdLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: thirdLevelOption?.options_id,
      options_values_id: thirdLevelOptionValue?.options_values_id,
      options_values_price: thirdLevelOptionValue?.options_values_price,
      attributes_max_select: thirdLevelOption?.attributes_max_select,
      attributes_min_select: thirdLevelOption?.attributes_min_select,
      products_options_name: thirdLevelOption?.products_options_name,
      products_options_values_name:
        thirdLevelOptionValue?.products_options_values_name,
      quantity: thirdLevelOptionValue?.quantity,
      free_modifier_quantity: thirdLevelOption?.free_modifier_quantity,
      optionValues1: thirdLevelOption?.optionValues,
      attributes_default: thirdLevelOptionValue?.attributes_default,
      fourth_level_attributes: [],
    };
    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == secondLevel?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) => item.options_values_id === thirdLevelData?.options_values_id
    );
    const newTemp =
      addonsObj?.attributes[firstIndex]?.second_level_attributes[newSecondIndex]
        ?.third_level_attributes;
    if (newTemp?.some((dl) => dl?.options_id == thirdLevelOption?.options_id)) {
      const pal = newTemp?.filter(
        (el) => el?.options_id !== thirdLevelOption?.options_id
      );
      pal.push(result);
      addonsObj.attributes[firstIndex].second_level_attributes[
        newSecondIndex
      ].third_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeTextThirdLevel = (
    secondLevel,
    event,
    thirdLevelOptionValue
  ) => {
    setAddonsObj((prevState) => ({
      ...prevState,
      attributes: prevState?.attributes?.map((dy) => {
        if (dy?.options_values_id == secondLevelData?.options_values_id) {
          return {
            ...dy,
            second_level_attributes: dy?.second_level_attributes?.map((el) => {
              if (el?.options_values_id == secondLevel?.options_values_id) {
                return {
                  ...el,
                  third_level_attributes: el?.third_level_attributes?.map(
                    (it) => {
                      if (
                        it?.options_values_id ==
                        thirdLevelOptionValue?.options_values_id
                      ) {
                        it.options_value_text = event.target.value;
                      }
                      return it;
                    }
                  ),
                };
              }
              return el;
            }),
          };
        } else {
          return dy;
        }
      }),
    }));
    setTemp(!temp);
  };

  const handelDisableCheckboxThirdLevel = (
    secondLevelOptionValue,
    thirdLevelOption,
    thirdLevelOptionValue
  ) => {
    const defaultOptionValue = addonsObj?.attributes
      ?.find(
        (ty) => ty?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (ty) =>
          ty?.options_values_id == secondLevelOptionValue?.options_values_id
      );
    if (
      thirdLevelOption?.attributes_min_select == 1 &&
      thirdLevelOption?.attributes_max_select == 1
    ) {
      const newTempId = defaultOptionValue?.third_level_attributes?.find(
        (dy) => dy?.options_id == thirdLevelOption?.options_id
      );

      if (newTempId) {
        const newTemp = defaultOptionValue?.third_level_attributes?.find(
          (dy) =>
            dy?.options_id == thirdLevelOption?.options_id &&
            dy?.options_values_id == thirdLevelOptionValue?.options_values_id
        );

        if (newTemp) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else if (
      thirdLevelOption?.attributes_min_select == 0 &&
      thirdLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.third_level_attributes?.filter(
        (dy) => dy?.options_id == thirdLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < thirdLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == thirdLevelOption?.options_id &&
            dy?.options_values_id == thirdLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    } else if (
      thirdLevelOption?.attributes_min_select > 0 &&
      thirdLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.third_level_attributes?.filter(
        (dy) => dy?.options_id == thirdLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < thirdLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == thirdLevelOption?.options_id &&
            dy?.options_values_id == thirdLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const checkedValueCommonFunction = (data) =>
    addonsObj?.attributes
      ?.find(
        (ft) => ft?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (ft) => ft?.options_values_id == thirdLevelData?.options_values_id
      )
      ?.third_level_attributes?.some(
        (el) => el?.options_values_id == data?.options_values_id
      );

  const checkedValueReturnFunction = (data) =>
    addonsObj?.attributes
      ?.find(
        (ft) => ft?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (ft) => ft?.options_values_id == thirdLevelData?.options_values_id
      )
      ?.third_level_attributes?.find(
        (el) => el?.options_values_id == data?.options_values_id
      );

  const handelChangeQtyThirdLevel = (
    event,
    secondLevelOptionValue,
    thirdLevelOption,
    thirdLevelOptionValue
  ) => {
    setAddonsObj((prevState) => ({
      ...prevState,
      attributes: prevState?.attributes?.map((dy) => {
        if (dy?.options_values_id == secondLevelData?.options_values_id) {
          return {
            ...dy,
            second_level_attributes: dy?.second_level_attributes?.map((el) => {
              if (
                el?.options_values_id ==
                secondLevelOptionValue?.options_values_id
              ) {
                return {
                  ...el,
                  third_level_attributes: el?.third_level_attributes?.map(
                    (it) => {
                      if (
                        it?.options_values_id ==
                        thirdLevelOptionValue?.options_values_id
                      ) {
                        if (event == "addition")
                          it.quantity =
                            it.quantity +
                            thirdLevelOption.increment_choice_quantity;
                        else
                          it.quantity =
                            it.quantity -
                            thirdLevelOption.increment_choice_quantity;
                      }
                      return { ...it };
                    }
                  ),
                };
              }
              return { ...el };
            }),
          };
        } else {
          return { ...dy };
        }
      }),
    }));
  };

  const renderThirdLevelButtonPriceCount = () => {
    const firstOptionsIds = [];
    const secondOptionsIds = [];
    const newTemp = addonsObj?.attributes
      ?.find((it) => it.options_values_id == secondLevelData.options_values_id)
      ?.second_level_attributes?.find(
        (it) => it.options_values_id == thirdLevelData.options_values_id
      );

    let totalPrice = 0;
    let firstOptionPrice = 0;
    let secondOptionPrice = 0;

    if (newTemp?.third_level_attributes?.length > 0) {
      for (let attribute of newTemp?.third_level_attributes) {
        firstOptionPrice = 0;
        secondOptionPrice = 0;

        if (attribute?.fourth_level_attributes?.length > 0) {
          for (let attributeNested of attribute?.fourth_level_attributes) {
            const { price, outerPrice } = checkAndReturnTotalPrice(
              attributeNested,
              secondOptionsIds,
              secondOptionPrice
            );
            totalPrice += price;
            secondOptionPrice = outerPrice;
          }
        }
        totalPrice += secondOptionPrice;
        const { price, outerPrice } = checkAndReturnTotalPrice(
          attribute,
          firstOptionsIds,
          firstOptionPrice
        );
        totalPrice += price;
        firstOptionPrice = outerPrice;
        totalPrice += firstOptionPrice;
      }
    }

    return (
      <span>
        <span>
          {totalPrice && !isNaN(totalPrice)
            ? "$" + totalPrice?.toFixed(2)
            : "Add"}
        </span>
      </span>
    );
  };

  const handelButtonClick = () => {
    let selectedOptions = addonsObj?.attributes
      ?.find(
        (rr) => rr?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (rr) => rr?.options_values_id == thirdLevelData?.options_values_id
      )?.third_level_attributes;

    const newthirdLevel = ADDONS_DETAILS?.find(
      (ty) => ty?.options_id == secondLevelData?.options_id
    )
      ?.optionValues?.find(
        (item) => item.options_values_id === secondLevelData?.options_values_id
      )
      ?.second_level_options?.find(
        (item) => item?.options_id == thirdLevelData?.options_id
      )
      ?.optionValues?.find(
        (item) => item.options_values_id === thirdLevelData?.options_values_id
      )?.third_level_options;

    const allOptionIds = [];
    const allSelectedOptionIds = [];
    const selectedArray = [];
    //return;
    selectedOptions?.map((slo) => {
      selectedArray.push(slo);
    });

    newthirdLevel?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });

    /* Code by ss */
    let anyError = false;
    let errorMessage = "";
    selectedOptions.forEach((attribute) => {
      const optionsValuesId = attribute.options_values_id;
      newthirdLevel.forEach((addon) => {
        const option = addon.optionValues.find(
          (option) => option.options_values_id === optionsValuesId
        );
        if (typeof option !== "undefined") {
          option?.fourth_level_options?.forEach((fourthLevelOption) => {
            if (
              fourthLevelOption.attributes_min_select > 0 &&
              attribute.fourth_level_attributes.length <
                fourthLevelOption.attributes_min_select
            ) {
              errorMessage =
                "Please select inner item of " +
                option?.products_options_values_name;
              anyError = true;
              return;
            }
          });
        }
      });
    });
    if (anyError) {
      errorToast(errorMessage);
      return;
    }
    // ********************

    for (let addon of newthirdLevel) {
      if (
        addon.products_options_type === 2 ||
        addon.products_options_type === 3
      ) {
        let optionsId = addon.options_id;
        if (addon.attributes_min_select > 0) {
          let selectedAttributes = selectedOptions.filter(
            (att) => att.options_id === optionsId
          );
          if (addon.attributes_min_select > selectedAttributes.length) {
            const errorMessage = `Minimum  ${addon.attributes_min_select}
              quantity is required for ${addon.products_options_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              // addon_details: newthirdLevel,
              // selectedAddon: selectedOptions,
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );

            anyError = true;
            break;
          }

          if (
            addon.attributes_max_select !== 0 &&
            addon.attributes_max_select < selectedAttributes.length
          ) {
            const errorMessage = `Maximum  ${addon.attributes_max_select}
              quantity can be choose for ${addon.products_options_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );
            anyError = true;
            break;
          }
        } else {
          const selectedAttributes = selectedOptions.find(
            (att) => att.options_id === optionsId
          );
          const selectedParticularAttributes =
            selectedAttributes?.optionValues1?.find(
              (it) =>
                it?.options_values_id === selectedAttributes?.options_values_id
            );
          const attributeDetails =
            selectedParticularAttributes?.fourth_level_options?.find(
              (it) => it?.attributes_min_select
            );
          if (
            attributeDetails?.attributes_min_select >
            selectedAttributes?.fourth_level_attributes?.filter(
              (it) => it.options_id === attributeDetails?.options_id
            ).length
          ) {
            const errorMessage = `Please select inner item of ${selectedParticularAttributes?.products_options_values_name}`;
            errorToast(errorMessage);
            const errorPayload = {
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );
            anyError = true;
            break;
          }
        }
        let totalQtySelected = 0;
        selectedOptions.forEach((attribute) => {
          if (attribute.options_id === optionsId) {
            totalQtySelected += attribute.quantity;
          }
        });

        if (
          addon?.support_choice_quantities &&
          totalQtySelected < addon.min_aggregate_quantity /* &&
          addon.attributes_min_select > 0 */
        ) {
          const errorMessage = `Minimum ${addon.min_aggregate_quantity}  quantity is required for ${addon.products_options_name}`;
          errorToast(errorMessage);
          const errorPayload = {
            addon_details: ADDONS_DETAILS,
            selectedAddon: addonsObj,
            errorMessage,
          };
          dispatch(
            GET_SAVE_CART_LOG(
              errorPayload,
              (res, otherRes, loading, error) => {}
            )
          );
          anyError = true;
          return;
        }
        //return;

        if (
          addon?.support_choice_quantities &&
          addon.max_aggregate_quantity !== 0 &&
          totalQtySelected > addon.max_aggregate_quantity
        ) {
          const errorMessage = `Maximum ${addon.max_aggregate_quantity} quantity can be choose for ${addon.products_options_name}`;
          errorToast(errorMessage);
          const errorPayload = {
            addon_details: ADDONS_DETAILS,
            selectedAddon: addonsObj,
            errorMessage,
          };
          dispatch(
            GET_SAVE_CART_LOG(
              errorPayload,
              (res, otherRes, loading, error) => {}
            )
          );
          anyError = true;
          return;
        }
      }
    }

    if (anyError) {
      return;
    }

    const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds = removeIds?.filter((ee) => {
      const newTemp = selectedArray?.find((et) => et?.options_id == ee);
      if (
        !(
          newTemp?.attributes_min_select <=
          allSelectedOptionIds?.filter((rt) => rt == ee).length
        )
      ) {
        return ee;
      }
    });
    setErrorHandler(removeIds);
    setTemp(!temp);
    if (removeIds.length == 0) {
      const firstIndex = addonsObj?.attributes?.findIndex(
        (rr) => rr?.options_values_id == secondLevelData?.options_values_id
      );
      const secondIndex = addonsObj.attributes[
        firstIndex
      ].second_level_attributes?.findIndex(
        (rr) => rr?.options_values_id == thirdLevelData?.options_values_id
      );
      if (secondIndex !== -1)
        addonsObj.attributes[firstIndex].second_level_attributes[
          secondIndex
        ].third_level_attributes = selectedOptions?.map((it) => {
          return {
            ...it,
            isAdd: true,
          };
        });
      setAddonsObj(addonsObj);
      setActiveNestedMode("secondLevel");
      setShowFourthLevelBlock(false);
      setVisibleThirdLevelBlock(false);
    } else {
      if (divRefs) {
        const targetRef = divRefs.current.find(
          (ref) => ref?.id == removeIds[0]
        );
        if (targetRef) {
          targetRef.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const handleBackTrigger = () => {
    const firstIndex = addonsObj?.attributes?.findIndex(
      (rr) => rr?.options_values_id == secondLevelData?.options_values_id
    );
    const secondIndex = addonsObj?.attributes[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (rr) => rr?.options_values_id == thirdLevelData?.options_values_id
    );
    let selectedOptions =
      addonsObj?.attributes?.[firstIndex]?.second_level_attributes?.[
        secondIndex
      ]?.third_level_attributes;
    const selectedArray = [];
    const allOptionIds = [];
    const allSelectedOptionIds = [];
    thirdLevelData?.third_level_options?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.map((slo) => {
      selectedArray.push(slo);

      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });
    const resultIdsArr = [
      ...allOptionIds,
      ...selectedArray?.map((it) => it?.options_id),
    ];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds?.map((ee) => {
      const addOn = { ...addonsObj };
      const newThirdLevel = ADDONS_DETAILS?.find(
        (ty) => ty?.options_id == secondLevelData?.options_id
      )
        ?.optionValues?.find(
          (item) =>
            item.options_values_id === secondLevelData?.options_values_id
        )
        ?.second_level_options?.find(
          (item) => item?.options_id == thirdLevelData?.options_id
        )
        ?.optionValues?.find(
          (item) => item.options_values_id === thirdLevelData?.options_values_id
        )?.third_level_options;

      if (firstIndex !== -1 && secondIndex !== -1) {
        const arr = newThirdLevel
          .map((it) =>
            it.optionValues
              ?.filter((el) => el.attributes_default > 0)
              ?.map((item) => ({
                ...item,
                products_options_name: it?.products_options_name,
                fourth_level_attributes: [],
              }))
          )
          .flat();
        const selected = selectedOptions?.filter((item) =>
          arr.some(
            (it) =>
              (it.options_values_id === item.options_values_id &&
                item?.isAdd) ||
              it.attributes_default > 0
          )
        );
        const valueSelected = selected.length > 0 ? selected : arr;
        addOn.attributes[firstIndex].second_level_attributes[
          secondIndex
        ].third_level_attributes = [
          ...selectedOptions?.filter(
            (it) => it.isAdd && it.attributes_default === 0
          ),
          ...valueSelected,
        ];
      }

      setAddonsObj(addOn);
    });
    let thirdLevelArr = selectedArray?.reduce((accumulator, currentItem) => {
      const index = accumulator.findIndex(
        (item) => item.options_id == currentItem.options_id
      );
      if (index !== -1) {
        accumulator[index].quantity += currentItem.quantity;
      } else {
        const option = thirdLevelData?.third_level_options?.find(
          (item) => item.options_id === currentItem.options_id
        );
        if (
          option?.min_aggregate_quantity != 0 ||
          option?.min_aggregate_quantity != 0
        ) {
          accumulator.push({
            options_id: currentItem.options_id,
            quantity: currentItem.quantity,
            min: option?.min_aggregate_quantity,
            max: option?.max_aggregate_quantity,
            product_name: option?.products_options_name,
          });
        }
      }
      return accumulator;
    }, []);
    let obj = { success: false, msg: "" };
    for (let newTemp of thirdLevelArr) {
      if (newTemp?.quantity > newTemp?.max) {
        obj = {
          success: true,
          msg: `Maximum ${newTemp?.max} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
      if (newTemp?.quantity < newTemp?.min) {
        obj = {
          success: true,
          msg: `Minimum ${newTemp?.min} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
    }

    if (obj?.success) {
      errorToast(obj?.msg);
      return null;
    }
    setActiveNestedMode("secondLevel");
    setShowFourthLevelBlock(false);
    setVisibleThirdLevelBlock(false);
  };

  const renderSelectionUI = (optionValue) => {
    const newTempp = addonsObj?.attributes
      ?.find(
        (rt) => rt?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (rt) => rt?.options_values_id == thirdLevelData?.options_values_id
      )
      ?.third_level_attributes?.find(
        (rt) => rt?.options_values_id == optionValue?.options_values_id
      );

    if (newTempp && Object?.keys(newTempp)?.length) {
      const newArr = {};
      newTempp?.fourth_level_attributes?.map((ry) => {
        if (newArr?.hasOwnProperty(ry?.options_id)) {
          newArr[ry?.options_id].push({
            option_name: ry?.products_options_name,
            options_value_name:
              RESTAURANTS?.is_display_modifier_quantity === 1
                ? ry?.quantity + " x " + ry?.products_options_values_name
                : ry?.products_options_values_name,
            options_value_text: ry?.options_value_text,
          });
        } else {
          newArr[ry?.options_id] = [];
          newArr[ry?.options_id].push({
            option_name: ry?.products_options_name,
            options_value_name:
              RESTAURANTS?.is_display_modifier_quantity === 1
                ? ry?.quantity + " x " + ry?.products_options_values_name
                : ry?.products_options_values_name,
            options_value_text: ry?.options_value_text,
          });
        }
      });

      return Object.keys(newArr)?.map((tt, index) => {
        let textValue = [];
        let textName = "";
        let specialName = undefined;
        newArr[tt].map((data) => {
          textValue.push(data?.options_value_name);
          textName = data?.option_name;
          specialName = data?.options_value_text;
        });

        return textName != "Special Instructions" ? (
          <>
            <div className="select-item border-bottom pb-2 mb-2" key={index}>
              <span className="fw-bold">{textName}</span>
              <small>{textValue?.join(", ")}</small>
            </div>
          </>
        ) : specialName ? (
          <div className="select-item border-bottom pb-2 mb-2" key={index}>
            <span className="fw-bold">{textName}</span>
            <small>{specialName}</small>
          </div>
        ) : null;
      });
    }
  };

  return (
    <div>
      <div className="nesting-modal">
        <h4 className="ps-md-4 ps-3">
          <Button className="bg-transparent border-0 text-dark ps-0">
            <ArrowBackIcon onClick={() => handleBackTrigger()} />
          </Button>
          {thirdLevelData?.products_options_values_name ?? ""}
        </h4>

        <div className="nesting-wrapper">
          {/* SECOND LEVEL MAIN START */}
          {thirdLevelData?.third_level_options &&
            thirdLevelData?.third_level_options?.length !== 0 &&
            thirdLevelData?.third_level_options?.map((third, index) => {
              return (
                <Fragment key={third?.options_id}>
                  {/* CHECKBOX UI START */}
                  {third?.products_options_type == 3 && (
                    <Fragment>
                      <div className="choose-option mb-2">
                        <div className="choose-option-inner">
                          {" "}
                          <h5
                            id={third?.options_id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            {third?.products_options_name}&nbsp;
                            {third?.free_modifier_quantity > 0 && (
                              <span style={{ fontSize: "13px" }}>
                                ({third?.free_modifier_quantity} Free option
                                available)
                              </span>
                            )}
                            {errorHandlerArr?.length > 0 &&
                            errorHandlerArr.includes(third?.options_id) ? (
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                }}
                              >
                                {"(Please select item)"}
                              </span>
                            ) : null}
                          </h5>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(third)}
                          </p>
                        </div>

                        {third?.optionValues &&
                          third?.optionValues?.length !== 0 &&
                          third?.optionValues?.map((checkOpt, ind) => {
                            return (
                              <FormGroup key={checkOpt?.options_values_id}>
                                <div className="nested-wrapper-sec d-flex justify-content-between align-items-center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: "",
                                          "&.Mui-checked": {
                                            color: "#F06F00",
                                          },
                                        }}
                                        checked={checkedValueCommonFunction(
                                          checkOpt
                                        )}
                                        onChange={(e) =>
                                          handelChangeCheckboxThirdLevel(
                                            e.target.checked,
                                            secondLevelData,
                                            third,
                                            checkOpt
                                          )
                                        }
                                        disabled={handelDisableCheckboxThirdLevel(
                                          thirdLevelData,
                                          third,
                                          checkOpt
                                        )}
                                      />
                                    }
                                    label={
                                      <>
                                        <div className="d-flex justify-content-between w-100 salad-wrapper">
                                          <span>
                                            {
                                              checkOpt?.products_options_values_name
                                            }
                                          </span>
                                        </div>
                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                          1 &&
                                          parseFloat(
                                            checkOpt?.options_values_price
                                          )?.toFixed(2) > 0 && (
                                            <span className="price fw-semibold">
                                              $
                                              {parseFloat(
                                                checkOpt?.options_values_price
                                              )?.toFixed(2)}
                                            </span>
                                          )}
                                      </>
                                    }
                                    className="w-100"
                                  />
                                  <div className="d-flex align-items-center nested-price-wrapper">
                                    {RESTAURANTS?.is_display_modifier_quantity ===
                                      1 && third?.support_choice_quantities ? (
                                      <div className="qty-wrap">
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              checkOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(checkOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              checkedValueReturnFunction(
                                                checkOpt
                                              )?.quantity >
                                              checkOpt.min_choice_quantity
                                            ) {
                                              handelChangeQtyThirdLevel(
                                                "subtract",
                                                thirdLevelData,
                                                third,
                                                checkOpt
                                              );
                                            }
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </button>

                                        <span
                                          className={
                                            checkedValueCommonFunction(checkOpt)
                                              ? "qty-count"
                                              : "qty-count qty-disable"
                                          }
                                        >
                                          {checkedValueReturnFunction(checkOpt)
                                            ?.quantity ?? 0}
                                        </span>
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              checkOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(checkOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              checkOpt.max_choice_quantity ===
                                                0 ||
                                              checkedValueReturnFunction(
                                                checkOpt
                                              )?.quantity <
                                                checkOpt.max_choice_quantity
                                            ) {
                                              handelChangeQtyThirdLevel(
                                                "addition",
                                                thirdLevelData,
                                                third,
                                                checkOpt
                                              );
                                            }
                                          }}
                                        >
                                          <ControlPointOutlinedIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(
                                          checkOpt?.options_values_price
                                        )?.toFixed(2) > 0 ? (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              checkOpt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                    {/* QUANTITY OF CHECKBOX */}
                                    {checkOpt?.fourth_level_options &&
                                    checkOpt?.fourth_level_options?.length !==
                                      0 ? (
                                      <>
                                        {showFourthLevelBlock &&
                                        activeFourthLevelBlock?.products_attributes_id ==
                                          checkOpt?.products_attributes_id ? (
                                          <ExpandLessIcon
                                            onClick={() => {
                                              if (
                                                checkedValueCommonFunction(
                                                  checkOpt
                                                )
                                              ) {
                                                handelShowFourthLevelModiefire(
                                                  third,
                                                  checkOpt,
                                                  "check",
                                                  false
                                                );
                                              }
                                            }}
                                            style={
                                              checkedValueCommonFunction(
                                                checkOpt
                                              )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        ) : (
                                          <ExpandMoreIcon
                                            onClick={() => {
                                              if (
                                                checkedValueCommonFunction(
                                                  checkOpt
                                                )
                                              ) {
                                                handelShowFourthLevelModiefire(
                                                  third,
                                                  checkOpt,
                                                  "check",
                                                  true
                                                );
                                              }
                                            }}
                                            style={
                                              checkedValueCommonFunction(
                                                checkOpt
                                              )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <span
                                        style={{
                                          width: "15px",
                                          marginLeft: "18px",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                {checkOpt?.fourth_level_options &&
                                  checkOpt?.fourth_level_options?.length !==
                                    0 && (
                                    <>
                                      {showFourthLevelBlock &&
                                        activeFourthLevelBlock?.products_attributes_id ==
                                          checkOpt?.products_attributes_id && (
                                          <Fragment>
                                            <div className="selection-required">
                                              {renderSelectionUI(checkOpt)}
                                              <div
                                                className="d-flex justify-content-between edit-selection"
                                                onClick={() =>
                                                  showFourthLevelBlockUi(
                                                    third,
                                                    checkOpt
                                                  )
                                                }
                                              >
                                                <h6 className="mb-0 text-decoration-underline">
                                                  Add selections
                                                </h6>
                                                <ArrowForwardIosIcon />
                                              </div>
                                            </div>
                                          </Fragment>
                                        )}
                                    </>
                                  )}
                                {/* QUANTITY OF CHECKBOX */}
                              </FormGroup>
                            );
                          })}
                      </div>
                    </Fragment>
                  )}
                  {/* CHECKBOX UI END */}
                  {/* RADIO UI START */}
                  {third?.products_options_type == 2 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner 123">
                        {" "}
                        <h5
                          id={third?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {third?.products_options_name}{" "}
                          {third?.free_modifier_quantity > 0 && (
                            <span style={{ fontSize: "13px" }}>
                              ({third?.free_modifier_quantity} Free option
                              available)
                            </span>
                          )}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(third?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(third)}
                          </p>
                        </h5>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      {/* <FormControl> */}
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        onChange={(e) =>
                          handelChangeRadioThirdLevel(
                            secondLevelData,
                            e.target.value,
                            third
                          )
                        }
                      >
                        {third?.optionValues &&
                          third?.optionValues?.length !== 0 &&
                          third?.optionValues?.map((radioOpt) => {
                            return (
                              <Fragment key={radioOpt?.options_values_id}>
                                <div className="nested-wrapper-sec d-flex justify-content-between align-items-center">
                                  <FormControlLabel
                                    value={JSON.stringify(radioOpt)}
                                    control={
                                      <Radio
                                        checked={checkedValueCommonFunction(
                                          radioOpt
                                        )}
                                        sx={{
                                          color: "",
                                          "&.Mui-checked": {
                                            color: "#F06F00",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <>
                                        <div className="d-flex justify-content-between w-100 salad-wrapper">
                                          <span>
                                            {
                                              radioOpt?.products_options_values_name
                                            }
                                          </span>
                                        </div>
                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                          1 &&
                                          parseFloat(
                                            radioOpt?.options_values_price
                                          )?.toFixed(2) > 0 && (
                                            <span className="price">
                                              $
                                              {parseFloat(
                                                radioOpt?.options_values_price
                                              )?.toFixed(2)}
                                            </span>
                                          )}
                                      </>
                                    }
                                  />
                                  <div className="d-flex align-items-center nested-price-wrapper">
                                    {RESTAURANTS?.is_display_modifier_quantity ===
                                      1 && third?.support_choice_quantities ? (
                                      <div className="qty-wrap">
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              radioOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(radioOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              checkedValueReturnFunction(
                                                radioOpt
                                              )?.quantity >
                                              radioOpt.min_choice_quantity
                                            ) {
                                              handelChangeQtyThirdLevel(
                                                "subtract",
                                                thirdLevelData,
                                                third,
                                                radioOpt
                                              );
                                            }
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </button>

                                        <span
                                          className={
                                            checkedValueCommonFunction(radioOpt)
                                              ? "qty-count"
                                              : "qty-count qty-disable"
                                          }
                                        >
                                          {checkedValueReturnFunction(radioOpt)
                                            ?.quantity ?? 0}
                                        </span>
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              radioOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(radioOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              radioOpt.max_choice_quantity ===
                                                0 ||
                                              checkedValueReturnFunction(
                                                radioOpt
                                              )?.quantity <
                                                radioOpt.max_choice_quantity
                                            ) {
                                              handelChangeQtyThirdLevel(
                                                "addition",
                                                thirdLevelData,
                                                third,
                                                radioOpt
                                              );
                                            }
                                          }}
                                        >
                                          <ControlPointOutlinedIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(
                                          radioOpt?.options_values_price
                                        )?.toFixed(2) > 0 ? (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              radioOpt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                    {/* QUANTITY OF CHECKBOX */}
                                    {radioOpt?.fourth_level_options &&
                                    radioOpt?.fourth_level_options?.length !==
                                      0 ? (
                                      <>
                                        {showFourthLevelBlock &&
                                        activeFourthLevelBlock?.products_attributes_id ==
                                          radioOpt?.products_attributes_id ? (
                                          <ExpandLessIcon
                                            onClick={() => {
                                              if (
                                                checkedValueCommonFunction(
                                                  radioOpt
                                                )
                                              ) {
                                                handelShowFourthLevelModiefire(
                                                  third,
                                                  radioOpt,
                                                  "check",
                                                  false
                                                );
                                              }
                                            }}
                                            style={
                                              checkedValueCommonFunction(
                                                radioOpt
                                              )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        ) : (
                                          <ExpandMoreIcon
                                            onClick={() => {
                                              if (
                                                checkedValueCommonFunction(
                                                  radioOpt
                                                )
                                              ) {
                                                handelShowFourthLevelModiefire(
                                                  third,
                                                  radioOpt,
                                                  "check",
                                                  true
                                                );
                                              }
                                            }}
                                            style={
                                              checkedValueCommonFunction(
                                                radioOpt
                                              )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <span
                                        style={{
                                          width: "15px",
                                          marginLeft: "18px",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                {radioOpt?.fourth_level_options &&
                                  radioOpt?.fourth_level_options?.length !==
                                    0 && (
                                    <>
                                      {showFourthLevelBlock &&
                                        activeFourthLevelBlock?.products_attributes_id ==
                                          radioOpt?.products_attributes_id && (
                                          <Fragment>
                                            <div className="selection-required">
                                              {renderSelectionUI(radioOpt)}
                                              <div
                                                className="d-flex justify-content-between edit-selection"
                                                onClick={() =>
                                                  showFourthLevelBlockUi(
                                                    third,
                                                    radioOpt
                                                  )
                                                }
                                              >
                                                <h6 className="mb-0 text-decoration-underline">
                                                  Add selections
                                                </h6>
                                                <ArrowForwardIosIcon />
                                              </div>
                                            </div>
                                          </Fragment>
                                        )}
                                    </>
                                  )}
                                {/* QUANTITY OF CHECKBOX */}
                              </Fragment>
                            );
                          })}
                      </RadioGroup>
                      {/* </FormControl> */}
                    </div>
                  )}
                  {/* RADIO UI START */}

                  {/* TEXT UI START */}
                  {third?.products_options_type == 1 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={third?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {third?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(third?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#000",
                            marginBottom: "0px",
                          }}
                        >
                          (additional charges may apply)
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(third)}
                        </p>
                      </div>

                      {third?.optionValues &&
                        third?.optionValues?.length !== 0 &&
                        third?.optionValues?.map((textOpt) => {
                          return (
                            <Fragment key={textOpt?.options_values_id}>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="my-3"
                                placeholder="Add Special instructions"
                                style={{ resize: "none" }}
                                onChange={(e) =>
                                  handelChangeTextThirdLevel(
                                    thirdLevelData,
                                    e,
                                    textOpt
                                  )
                                }
                                value={
                                  checkedValueReturnFunction(textOpt)
                                    ?.options_value_text
                                }
                              />
                            </Fragment>
                          );
                        })}
                    </div>
                  )}
                  {/* TEXT UI END  */}

                  {/* DROPDOWN UI START */}
                  {third?.products_options_type == 0 && (
                    <div className="choose-option mb-2 build-own-li">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={third?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {third?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(third?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(third)}
                        </p>
                      </div>

                      <div className="product-data-sec">
                        <DropdownButton
                          className="form-control p-0"
                          title={
                            <h6 className="d-flex justify-content-between w-100 pe-3 mb-0 h-100 align-items-center ">
                              {renderDropdownTitleThirdLevel(
                                secondLevelData,
                                thirdLevelData,
                                third,
                                addonsObj
                              )}
                            </h6>
                          }
                          id="dropdown-menu-align-right"
                          onSelect={(event) =>
                            handelChangeDropdownThirdLevel(
                              secondLevelData,
                              event,
                              third
                            )
                          }
                        >
                          {third?.optionValues &&
                            third?.optionValues?.length !== 0 &&
                            third?.optionValues?.map((dropOpt) => {
                              return (
                                <Fragment key={dropOpt?.products_attributes_id}>
                                  <Dropdown.Item
                                    eventKey={JSON.stringify(dropOpt)}
                                    active={checkedValueCommonFunction(dropOpt)}
                                  >
                                    {dropOpt?.products_options_values_name}
                                    {parseFloat(
                                      dropOpt?.options_values_price
                                    )?.toFixed(2) > 0 &&
                                      dropOpt?.options_values_price && (
                                        <span
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          $
                                          {parseFloat(
                                            dropOpt?.options_values_price
                                          )?.toFixed(2)}
                                        </span>
                                      )}
                                  </Dropdown.Item>
                                </Fragment>
                              );
                            })}
                        </DropdownButton>
                      </div>
                    </div>
                  )}
                  {/* DROPDOWN UI END */}
                </Fragment>
              );
            })}
          {/* THIRD LEVEL MAIN END */}
        </div>

        <div className="nesting-footer place-order-btn">
          <Button
            className="common-btn w-100 add-to-order"
            type="button"
            onClick={() => handelButtonClick()}
          >
            {renderThirdLevelButtonPriceCount()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThirdLevelBlock;
