import React from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Fragment, useRef } from "react";
import {
  checkAndReturnTotalPrice,
  removeDuplicates,
  renderCheckboxRequiredOpt,
  renderDropdownTitleFourthLevel,
} from "helpers/AddonsModal/AddonsModalHocFunctions";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { errorToast } from "utils/toastMessage";

const FourthLevelBlock = ({
  setVisibleFourthLevelBlock,
  secondLevelData,
  thirdLevelData,
  fourthLevelData,
  addonsObj,
  setAddonsObj,
  setTemp,
  temp,
  ADDONS_DETAILS,
  setErrorHandler,
  errorHandlerArr,
  RESTAURANTS,
  setActiveNestedMode,
}) => {
  const divRefs = useRef([]);
  const handelChangeCheckboxFourthLevel = (
    event,
    thirdLevel,
    fourthLevel,
    fourthLevelOptionValue
  ) => {
    const result = {
      options_id: fourthLevel?.options_id,
      options_values_id: fourthLevelOptionValue?.options_values_id,
      options_values_price: fourthLevelOptionValue?.options_values_price,
      // options_value_text: "",
      attributes_max_select: fourthLevel?.attributes_max_select,
      attributes_min_select: fourthLevel?.attributes_min_select,
      products_options_name: fourthLevel?.products_options_name,
      products_options_values_name:
        fourthLevelOptionValue?.products_options_values_name,
      quantity: fourthLevel?.increment_choice_quantity,
      optionValues1: fourthLevel?.optionValues,
      free_modifier_quantity: fourthLevel?.free_modifier_quantity,
      attributes_default: fourthLevelOptionValue?.attributes_default,
    };
    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == secondLevelData?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) => item.options_values_id === thirdLevel?.options_values_id
    );
    const newThirdIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.[
      newSecondIndex
    ]?.third_level_attributes?.findIndex(
      (item) => item.options_values_id === fourthLevelData?.options_values_id
    );
    if (event) {
      if (newThirdIndex !== -1)
        addonsObj?.attributes[firstIndex].second_level_attributes[
          newSecondIndex
        ].third_level_attributes[newThirdIndex].fourth_level_attributes.push(
          result
        );
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      addonsObj.attributes[firstIndex].second_level_attributes[
        newSecondIndex
      ].third_level_attributes[newThirdIndex].fourth_level_attributes =
        addonsObj?.attributes[firstIndex].second_level_attributes[
          newSecondIndex
        ].third_level_attributes[newThirdIndex].fourth_level_attributes?.filter(
          (el) =>
            el?.options_values_id !== fourthLevelOptionValue.options_values_id
        );
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeRadioFourthLevel = (thirdLevel, event, fourthLevel) => {
    const fourthLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: fourthLevel?.options_id,
      options_values_id: fourthLevelOptionValue?.options_values_id,
      options_values_price: fourthLevelOptionValue?.options_values_price,
      attributes_max_select: fourthLevel?.attributes_max_select,
      attributes_min_select: fourthLevel?.attributes_min_select,
      products_options_name: fourthLevel?.products_options_name,
      products_options_values_name:
        fourthLevelOptionValue?.products_options_values_name,
      quantity: fourthLevelOptionValue?.quantity,
      optionValues1: fourthLevel?.optionValues,
      free_modifier_quantity: fourthLevel?.free_modifier_quantity,
      attributes_default: fourthLevelOptionValue?.attributes_default,
    };

    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == secondLevelData?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) => item.options_values_id === thirdLevel?.options_values_id
    );
    const newThirdIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.[
      newSecondIndex
    ]?.third_level_attributes?.findIndex(
      (item) => item.options_values_id === fourthLevelData?.options_values_id
    );
    const newTemp =
      addonsObj?.attributes[firstIndex]?.second_level_attributes[newSecondIndex]
        ?.third_level_attributes[newThirdIndex]?.fourth_level_attributes;
    if (newTemp?.some((dl) => dl?.options_id == fourthLevel?.options_id)) {
      const pal = newTemp?.filter(
        (el) => el?.options_id !== fourthLevel?.options_id
      );
      pal.push(result);
      addonsObj.attributes[firstIndex].second_level_attributes[
        newSecondIndex
      ].third_level_attributes[newThirdIndex].fourth_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeDropdownFourthLevel = (
    thirdLevel,
    event,
    fourthLevelOption
  ) => {
    const fourthLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: fourthLevelOption?.options_id,
      options_values_id: fourthLevelOptionValue?.options_values_id,
      options_values_price: fourthLevelOptionValue?.options_values_price,
      attributes_max_select: fourthLevelOption?.attributes_max_select,
      attributes_min_select: fourthLevelOption?.attributes_min_select,
      products_options_name: fourthLevelOption?.products_options_name,
      products_options_values_name:
        fourthLevelOptionValue?.products_options_values_name,
      quantity: fourthLevelOptionValue?.quantity,
      free_modifier_quantity: fourthLevelOption?.free_modifier_quantity,
      optionValues1: fourthLevelOption?.optionValues,
      attributes_default: fourthLevelOptionValue?.attributes_default,
    };
    const firstIndex = addonsObj?.attributes?.findIndex(
      (ty) => ty?.options_values_id == secondLevelData?.options_values_id
    );
    const newSecondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (item) => item.options_values_id === thirdLevel?.options_values_id
    );
    const newThirdIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.[
      newSecondIndex
    ]?.third_level_attributes?.findIndex(
      (item) => item.options_values_id === fourthLevelData?.options_values_id
    );
    const newTemp =
      addonsObj?.attributes[firstIndex]?.second_level_attributes[newSecondIndex]
        ?.third_level_attributes[newThirdIndex]?.fourth_level_attributes;
    if (
      newTemp?.some((dl) => dl?.options_id == fourthLevelOption?.options_id)
    ) {
      const pal = newTemp?.filter(
        (el) => el?.options_id !== fourthLevelOption?.options_id
      );
      pal.push(result);
      addonsObj.attributes[firstIndex].second_level_attributes[
        newSecondIndex
      ].third_level_attributes[newThirdIndex].fourth_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handleChangeTextFourthLevel = (
    fourthLevel,
    event,
    fourthLevelOptionValue
  ) => {
    setAddonsObj((prevState) => ({
      ...prevState,
      attributes: prevState?.attributes?.map((dy) => {
        if (dy?.options_values_id == secondLevelData?.options_values_id) {
          return {
            ...dy,
            second_level_attributes: dy?.second_level_attributes?.map((el) => {
              if (el?.options_values_id == thirdLevelData?.options_values_id) {
                return {
                  ...el,
                  third_level_attributes: el?.third_level_attributes?.map(
                    (it) => {
                      if (
                        it?.options_values_id == fourthLevel?.options_values_id
                      ) {
                        return {
                          ...it,
                          fourth_level_attributes:
                            it?.fourth_level_attributes?.map((item) => {
                              if (
                                item?.options_values_id ==
                                fourthLevelOptionValue?.options_values_id
                              ) {
                                item.options_value_text = event.target.value;
                              }
                              return item;
                            }),
                        };
                      }
                      return it;
                    }
                  ),
                };
              }
              return el;
            }),
          };
        } else {
          return dy;
        }
      }),
    }));
    setTemp(!temp);
  };

  const handelDisableCheckboxFourthLevel = (
    thirdLevelOptionValue,
    fourthLevelOption,
    fourthLevelOptionValue
  ) => {
    const defaultOptionValue = addonsObj?.attributes
      ?.find(
        (ty) => ty?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (ty) => ty?.options_values_id == thirdLevelData?.options_values_id
      )
      ?.third_level_attributes?.find(
        (ty) =>
          ty?.options_values_id == thirdLevelOptionValue?.options_values_id
      );
    if (
      fourthLevelOption?.attributes_min_select == 1 &&
      fourthLevelOption?.attributes_max_select == 1
    ) {
      const newTempId = defaultOptionValue?.fourth_level_attributes?.find(
        (dy) => dy?.options_id == fourthLevelOption?.options_id
      );

      if (newTempId) {
        const newTemp = defaultOptionValue?.fourth_level_attributes?.find(
          (dy) =>
            dy?.options_id == fourthLevelOption?.options_id &&
            dy?.options_values_id == fourthLevelOptionValue?.options_values_id
        );

        if (newTemp) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else if (
      fourthLevelOption?.attributes_min_select == 0 &&
      fourthLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.fourth_level_attributes?.filter(
        (dy) => dy?.options_id == fourthLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < fourthLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == fourthLevelOption?.options_id &&
            dy?.options_values_id == fourthLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    } else if (
      fourthLevelOption?.attributes_min_select > 0 &&
      fourthLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.fourth_level_attributes?.filter(
        (dy) => dy?.options_id == fourthLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < fourthLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == fourthLevelOption?.options_id &&
            dy?.options_values_id == fourthLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const checkedValueCommonFunction = (data) =>
    addonsObj?.attributes
      ?.find(
        (ft) => ft?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (ft) => ft?.options_values_id == thirdLevelData?.options_values_id
      )
      ?.third_level_attributes?.find(
        (ft) => ft?.options_values_id == fourthLevelData?.options_values_id
      )
      ?.fourth_level_attributes?.some(
        (el) => el?.options_values_id == data?.options_values_id
      );

  const checkedValueReturnFunction = (data) =>
    addonsObj?.attributes
      ?.find(
        (ft) => ft?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (ft) => ft?.options_values_id == thirdLevelData?.options_values_id
      )
      ?.third_level_attributes?.find(
        (ft) => ft?.options_values_id == fourthLevelData?.options_values_id
      )
      ?.fourth_level_attributes?.find(
        (el) => el?.options_values_id == data?.options_values_id
      );

  const handelChangeQtyFourthLevel = (
    event,
    thirdLevelOptionValue,
    fourthLevelOption,
    fourthLevelOptionValue
  ) => {
    setAddonsObj((prevState) => ({
      ...prevState,
      attributes: prevState?.attributes?.map((dy) => {
        if (dy?.options_values_id == secondLevelData?.options_values_id) {
          return {
            ...dy,
            second_level_attributes: dy?.second_level_attributes?.map((el) => {
              if (el?.options_values_id == thirdLevelData?.options_values_id) {
                return {
                  ...el,
                  third_level_attributes: el?.third_level_attributes?.map(
                    (it) => {
                      if (
                        it?.options_values_id ==
                        thirdLevelOptionValue?.options_values_id
                      ) {
                        return {
                          ...it,
                          fourth_level_attributes:
                            it?.fourth_level_attributes?.map((item) => {
                              if (
                                item?.options_values_id ==
                                fourthLevelOptionValue?.options_values_id
                              ) {
                                if (event == "addition")
                                  item.quantity =
                                    item.quantity +
                                    fourthLevelOption.increment_choice_quantity;
                                else
                                  item.quantity =
                                    item.quantity -
                                    fourthLevelOption.increment_choice_quantity;
                              }
                              return { ...item };
                            }),
                        };
                      }
                      return { ...it };
                    }
                  ),
                };
              }
              return { ...el };
            }),
          };
        } else {
          return { ...dy };
        }
      }),
    }));
  };

  const renderFourthLevelButtonPriceCount = () => {
    const firstOptionsIds = [];
    const newTemp = addonsObj?.attributes
      ?.find((it) => it.options_values_id == secondLevelData.options_values_id)
      ?.second_level_attributes?.find(
        (it) => it.options_values_id == thirdLevelData.options_values_id
      )
      ?.third_level_attributes?.find(
        (it) => it.options_values_id == fourthLevelData.options_values_id
      );

    let totalPrice = 0;
    let firstOptionPrice = 0;

    if (newTemp?.fourth_level_attributes?.length > 0) {
      for (let attribute of newTemp?.fourth_level_attributes) {
        firstOptionPrice = 0;
        const { price, outerPrice } = checkAndReturnTotalPrice(
          attribute,
          firstOptionsIds,
          firstOptionPrice
        );
        totalPrice += price;
        firstOptionPrice = outerPrice;
        totalPrice += firstOptionPrice;
      }
    }

    return (
      <span>
        <span>
          {totalPrice && !isNaN(totalPrice)
            ? "$" + totalPrice?.toFixed(2)
            : "Add"}
        </span>
      </span>
    );
  };

  const handelButtonClick = () => {
    const firstIndex = addonsObj?.attributes?.findIndex(
      (rr) => rr?.options_values_id == secondLevelData?.options_values_id
    );
    const secondIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (rr) => rr?.options_values_id == thirdLevelData?.options_values_id
    );
    const thirdIndex = addonsObj?.attributes?.[
      firstIndex
    ]?.second_level_attributes?.[
      secondIndex
    ]?.third_level_attributes?.findIndex(
      (rr) => rr?.options_values_id == fourthLevelData?.options_values_id
    );
    let selectedOptions =
      addonsObj?.attributes?.[firstIndex]?.second_level_attributes?.[
        secondIndex
      ]?.third_level_attributes?.[thirdIndex];

    const allOptionIds = [];
    const allSelectedOptionIds = [];
    const selectedArray = [];
    selectedOptions?.fourth_level_attributes?.map((slo) => {
      selectedArray.push(slo);
    });
    fourthLevelData?.fourth_level_options?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.fourth_level_attributes?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });

    const addOn = { ...addonsObj };
    if (secondIndex !== -1)
      addOn.attributes[firstIndex].second_level_attributes[
        secondIndex
      ].third_level_attributes[thirdIndex].fourth_level_attributes =
        selectedOptions.fourth_level_attributes?.map((it) => {
          return {
            ...it,
            isAdd: true,
          };
        });
    setAddonsObj(addOn);

    let secondLevelArr = selectedArray?.reduce((accumulator, currentItem) => {
      const index = accumulator.findIndex(
        (item) => item?.options_id == currentItem?.options_id
      );
      if (index !== -1) {
        accumulator[index].quantity += currentItem.quantity;
      } else {
        const option = fourthLevelData?.fourth_level_options?.find(
          (item) => item?.options_id === currentItem?.options_id
        );
        if (
          option?.min_aggregate_quantity != 0 ||
          option?.max_aggregate_quantity !== 0
        ) {
          accumulator.push({
            options_id: currentItem.options_id,
            quantity: currentItem.quantity,
            min: option?.min_aggregate_quantity,
            max: option?.max_aggregate_quantity,
            product_name: option?.products_options_name,
          });
        }
      }
      return accumulator;
    }, []);
    let obj = { success: false, msg: "" };
    for (let newTemp of secondLevelArr) {
      if (newTemp?.quantity > newTemp?.max) {
        obj = {
          success: true,
          msg: `Maximum ${newTemp?.max} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
      if (newTemp?.quantity < newTemp?.min) {
        obj = {
          success: true,
          msg: `Minimum ${newTemp?.min} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
    }

    if (obj?.success) {
      errorToast(obj?.msg);
      return null;
    }

    const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds = removeIds?.filter((ee) => {
      const newTemp = selectedArray?.find((et) => et?.options_id == ee);
      if (
        !(
          newTemp?.attributes_min_select <=
          allSelectedOptionIds?.filter((rt) => rt == ee).length
        )
      ) {
        return ee;
      }
    });
    setErrorHandler(removeIds);
    setTemp(!temp);
    if (removeIds.length == 0) {
      setActiveNestedMode("thirdLevel");
      setVisibleFourthLevelBlock(false);
    } else {
      if (divRefs) {
        const targetRef = divRefs.current.find(
          (ref) => ref?.id == removeIds[0]
        );
        if (targetRef) {
          targetRef.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const handleBackTrigger = () => {
    const firstIndex = addonsObj?.attributes?.findIndex(
      (rr) => rr?.options_values_id == secondLevelData?.options_values_id
    );
    const secondIndex = addonsObj?.attributes[
      firstIndex
    ]?.second_level_attributes?.findIndex(
      (rr) => rr?.options_values_id == thirdLevelData?.options_values_id
    );
    const thirdIndex = addonsObj?.attributes[
      firstIndex
    ]?.second_level_attributes?.[
      secondIndex
    ]?.third_level_attributes?.findIndex(
      (rr) => rr?.options_values_id == fourthLevelData?.options_values_id
    );
    let selectedOptions =
      addonsObj?.attributes?.[firstIndex]?.second_level_attributes?.[
        secondIndex
      ]?.third_level_attributes?.[thirdIndex]?.fourth_level_attributes;
    const selectedArray = [];
    const allOptionIds = [];
    const allSelectedOptionIds = [];
    fourthLevelData?.fourth_level_options?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.map((slo) => {
      selectedArray.push(slo);

      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });
    const resultIdsArr = [
      ...allOptionIds,
      ...selectedArray?.map((it) => it?.options_id),
    ];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds?.map((ee) => {
      const addOn = { ...addonsObj };
      const newFourthLevel = ADDONS_DETAILS?.find(
        (ty) => ty?.options_id == secondLevelData?.options_id
      )
        ?.optionValues?.find(
          (item) =>
            item.options_values_id === secondLevelData?.options_values_id
        )
        ?.second_level_options?.find(
          (item) => item?.options_id == thirdLevelData?.options_id
        )
        ?.optionValues?.find(
          (item) => item.options_values_id === thirdLevelData?.options_values_id
        )
        ?.third_level_options?.find(
          (item) => item?.options_id == fourthLevelData?.options_id
        )
        ?.optionValues?.find(
          (item) =>
            item.options_values_id === fourthLevelData?.options_values_id
        )?.fourth_level_options;

      if (firstIndex !== -1 && secondIndex !== -1 && thirdIndex !== -1) {
        const arr = newFourthLevel
          .map((it) =>
            it.optionValues
              ?.filter((el) => el.attributes_default > 0)
              ?.map((item) => ({
                ...item,
                products_options_name: it?.products_options_name,
              }))
          )
          .flat();
        const selected = selectedOptions?.filter((item) =>
          arr.some(
            (it) =>
              (it.options_values_id === item.options_values_id &&
                item?.isAdd) ||
              it.attributes_default > 0
          )
        );
        const valueSelected = selected.length > 0 ? selected : arr;
        addOn.attributes[firstIndex].second_level_attributes[
          secondIndex
        ].third_level_attributes[thirdIndex].fourth_level_attributes = [
          ...selectedOptions?.filter(
            (it) => it.isAdd && it.attributes_default === 0
          ),
          ...valueSelected,
        ];
      }

      setAddonsObj(addOn);
    });
    let fourthLevelArr = selectedArray?.reduce((accumulator, currentItem) => {
      const index = accumulator.findIndex(
        (item) => item.options_id == currentItem.options_id
      );
      if (index !== -1) {
        accumulator[index].quantity += currentItem.quantity;
      } else {
        const option = fourthLevelData?.fourth_level_options?.find(
          (item) => item.options_id === currentItem.options_id
        );
        if (
          option?.min_aggregate_quantity != 0 ||
          option?.min_aggregate_quantity != 0
        ) {
          accumulator.push({
            options_id: currentItem.options_id,
            quantity: currentItem.quantity,
            min: option?.min_aggregate_quantity,
            max: option?.max_aggregate_quantity,
            product_name: option?.products_options_name,
          });
        }
      }
      return accumulator;
    }, []);
    let obj = { success: false, msg: "" };
    for (let newTemp of fourthLevelArr) {
      if (newTemp?.quantity > newTemp?.max) {
        obj = {
          success: true,
          msg: `Maximum ${newTemp?.max} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
      if (newTemp?.quantity < newTemp?.min) {
        obj = {
          success: true,
          msg: `Minimum ${newTemp?.min} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
    }

    if (obj?.success) {
      errorToast(obj?.msg);
      return null;
    }
    setActiveNestedMode("thirdLevel");
    setVisibleFourthLevelBlock(false);
  };
  return (
    <div>
      <div className="nesting-modal">
        <h4 className="ps-md-4 ps-3">
          <Button className="bg-transparent border-0 text-dark ps-0">
            <ArrowBackIcon onClick={() => handleBackTrigger()} />
          </Button>
          {fourthLevelData?.products_options_values_name ?? ""}
        </h4>

        <div className="nesting-wrapper">
          {/* SECOND LEVEL MAIN START */}
          {fourthLevelData?.fourth_level_options &&
            fourthLevelData?.fourth_level_options?.length !== 0 &&
            fourthLevelData?.fourth_level_options?.map((fourth, index) => {
              return (
                <Fragment key={fourth?.options_id}>
                  {/* CHECKBOX UI START */}
                  {fourth?.products_options_type == 3 && (
                    <Fragment>
                      <div className="choose-option mb-2">
                        <div className="choose-option-inner">
                          {" "}
                          <h5
                            id={fourth?.options_id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            {fourth?.products_options_name}&nbsp;
                            {fourth?.free_modifier_quantity > 0 && (
                              <span style={{ fontSize: "13px" }}>
                                ({fourth?.free_modifier_quantity} Free option
                                available)
                              </span>
                            )}
                            {errorHandlerArr?.length > 0 &&
                            errorHandlerArr.includes(fourth?.options_id) ? (
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                }}
                              >
                                {"(Please select item)"}
                              </span>
                            ) : null}
                          </h5>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(fourth)}
                          </p>
                        </div>

                        {fourth?.optionValues &&
                          fourth?.optionValues?.length !== 0 &&
                          fourth?.optionValues?.map((checkOpt, ind) => {
                            return (
                              <FormGroup key={checkOpt?.options_values_id}>
                                <div className="nested-wrapper-sec d-flex justify-content-between align-items-center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: "",
                                          "&.Mui-checked": {
                                            color: "#F06F00",
                                          },
                                        }}
                                        checked={checkedValueCommonFunction(
                                          checkOpt
                                        )}
                                        onChange={(e) =>
                                          handelChangeCheckboxFourthLevel(
                                            e.target.checked,
                                            thirdLevelData,
                                            fourth,
                                            checkOpt
                                          )
                                        }
                                        disabled={handelDisableCheckboxFourthLevel(
                                          fourthLevelData,
                                          fourth,
                                          checkOpt
                                        )}
                                      />
                                    }
                                    label={
                                      <>
                                        <div className="d-flex justify-content-between w-100 salad-wrapper">
                                          <span>
                                            {
                                              checkOpt?.products_options_values_name
                                            }
                                          </span>
                                        </div>
                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                          1 &&
                                          parseFloat(
                                            checkOpt?.options_values_price
                                          )?.toFixed(2) > 0 && (
                                            <span className="price fw-semibold">
                                              $
                                              {parseFloat(
                                                checkOpt?.options_values_price
                                              )?.toFixed(2)}
                                            </span>
                                          )}
                                      </>
                                    }
                                    className="w-100"
                                  />
                                  <div className="d-flex align-items-center nested-price-wrapper">
                                    {RESTAURANTS?.is_display_modifier_quantity ===
                                      1 && fourth?.support_choice_quantities ? (
                                      <div className="qty-wrap">
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              checkOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(checkOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              checkedValueReturnFunction(
                                                checkOpt
                                              )?.quantity >
                                              checkOpt.min_choice_quantity
                                            ) {
                                              handelChangeQtyFourthLevel(
                                                "subtract",
                                                fourthLevelData,
                                                fourth,
                                                checkOpt
                                              );
                                            }
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </button>

                                        <span
                                          className={
                                            checkedValueCommonFunction(checkOpt)
                                              ? "qty-count"
                                              : "qty-count qty-disable"
                                          }
                                        >
                                          {checkedValueReturnFunction(checkOpt)
                                            ?.quantity ?? 0}
                                        </span>
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              checkOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(checkOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              checkOpt.max_choice_quantity ===
                                                0 ||
                                              checkedValueReturnFunction(
                                                checkOpt
                                              )?.quantity <
                                                checkOpt.max_choice_quantity
                                            ) {
                                              handelChangeQtyFourthLevel(
                                                "addition",
                                                fourthLevelData,
                                                fourth,
                                                checkOpt
                                              );
                                            }
                                          }}
                                        >
                                          <ControlPointOutlinedIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(
                                          checkOpt?.options_values_price
                                        )?.toFixed(2) > 0 ? (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              checkOpt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                            );
                          })}
                      </div>
                    </Fragment>
                  )}
                  {/* CHECKBOX UI END */}
                  {/* RADIO UI START */}
                  {fourth?.products_options_type == 2 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner 123">
                        {" "}
                        <h5
                          id={fourth?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {fourth?.products_options_name}{" "}
                          {fourth?.free_modifier_quantity > 0 && (
                            <span style={{ fontSize: "13px" }}>
                              ({fourth?.free_modifier_quantity} Free option
                              available)
                            </span>
                          )}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(fourth?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(fourth)}
                          </p>
                        </h5>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      {/* <FormControl> */}
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        onChange={(e) =>
                          handelChangeRadioFourthLevel(
                            thirdLevelData,
                            e.target.value,
                            fourth
                          )
                        }
                      >
                        {fourth?.optionValues &&
                          fourth?.optionValues?.length !== 0 &&
                          fourth?.optionValues?.map((radioOpt) => {
                            return (
                              <Fragment key={radioOpt?.options_values_id}>
                                <div className="nested-wrapper-sec d-flex justify-content-between align-items-center">
                                  <FormControlLabel
                                    value={JSON.stringify(radioOpt)}
                                    control={
                                      <Radio
                                        checked={checkedValueCommonFunction(
                                          radioOpt
                                        )}
                                        sx={{
                                          color: "",
                                          "&.Mui-checked": {
                                            color: "#F06F00",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <>
                                        <div className="d-flex justify-content-between w-100 salad-wrapper">
                                          <span>
                                            {
                                              radioOpt?.products_options_values_name
                                            }
                                          </span>
                                        </div>
                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                          1 &&
                                          parseFloat(
                                            radioOpt?.options_values_price
                                          )?.toFixed(2) > 0 && (
                                            <span className="price">
                                              $
                                              {parseFloat(
                                                radioOpt?.options_values_price
                                              )?.toFixed(2)}
                                            </span>
                                          )}
                                      </>
                                    }
                                  />
                                  <div className="d-flex align-items-center nested-price-wrapper">
                                    {RESTAURANTS?.is_display_modifier_quantity ===
                                      1 && fourth?.support_choice_quantities ? (
                                      <div className="qty-wrap">
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              radioOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(radioOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              checkedValueReturnFunction(
                                                radioOpt
                                              )?.quantity >
                                              radioOpt.min_choice_quantity
                                            ) {
                                              handelChangeQtyFourthLevel(
                                                "subtract",
                                                fourthLevelData,
                                                fourth,
                                                radioOpt
                                              );
                                            }
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </button>

                                        <span
                                          className={
                                            checkedValueCommonFunction(radioOpt)
                                              ? "qty-count"
                                              : "qty-count qty-disable"
                                          }
                                        >
                                          {checkedValueReturnFunction(radioOpt)
                                            ?.quantity ?? 0}
                                        </span>
                                        <button
                                          type="button"
                                          disabled={
                                            !checkedValueCommonFunction(
                                              radioOpt
                                            )
                                          }
                                          className={
                                            checkedValueCommonFunction(radioOpt)
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              radioOpt.max_choice_quantity ===
                                                0 ||
                                              checkedValueReturnFunction(
                                                radioOpt
                                              )?.quantity <
                                                radioOpt.max_choice_quantity
                                            ) {
                                              handelChangeQtyFourthLevel(
                                                "addition",
                                                fourthLevelData,
                                                fourth,
                                                radioOpt
                                              );
                                            }
                                          }}
                                        >
                                          <ControlPointOutlinedIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(
                                          radioOpt?.options_values_price
                                        )?.toFixed(2) > 0 ? (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              radioOpt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Fragment>
                            );
                          })}
                      </RadioGroup>
                      {/* </FormControl> */}
                    </div>
                  )}
                  {/* RADIO UI START */}

                  {/* TEXT UI START */}
                  {fourth?.products_options_type == 1 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={fourth?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {fourth?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(fourth?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#000",
                            marginBottom: "0px",
                          }}
                        >
                          (additional charges may apply)
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(fourth)}
                        </p>
                      </div>

                      {fourth?.optionValues &&
                        fourth?.optionValues?.length !== 0 &&
                        fourth?.optionValues?.map((textOpt) => {
                          return (
                            <Fragment key={textOpt?.options_values_id}>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="my-3"
                                placeholder="Add Special instructions"
                                style={{ resize: "none" }}
                                onChange={(e) =>
                                  handleChangeTextFourthLevel(
                                    fourthLevelData,
                                    e,
                                    textOpt
                                  )
                                }
                                value={
                                  checkedValueReturnFunction(textOpt)
                                    ?.options_value_text
                                }
                              />
                            </Fragment>
                          );
                        })}
                    </div>
                  )}
                  {/* TEXT UI END  */}

                  {/* DROPDOWN UI START */}
                  {fourth?.products_options_type == 0 && (
                    <div className="choose-option mb-2 build-own-li">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={fourth?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {fourth?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(fourth?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(fourth)}
                        </p>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      <div className="product-data-sec">
                        <DropdownButton
                          className="form-control p-0"
                          title={
                            <h6 className="d-flex justify-content-between w-100 pe-3 mb-0 h-100 align-items-center ">
                              {renderDropdownTitleFourthLevel(
                                secondLevelData,
                                thirdLevelData,
                                fourthLevelData,
                                fourth,
                                addonsObj
                              )}
                            </h6>
                          }
                          id="dropdown-menu-align-right"
                          onSelect={(event) =>
                            handelChangeDropdownFourthLevel(
                              thirdLevelData,
                              event,
                              fourth
                            )
                          }
                        >
                          {fourth?.optionValues &&
                            fourth?.optionValues?.length !== 0 &&
                            fourth?.optionValues?.map((dropOpt) => {
                              return (
                                <Fragment key={dropOpt?.products_attributes_id}>
                                  <Dropdown.Item
                                    eventKey={JSON.stringify(dropOpt)}
                                    active={checkedValueCommonFunction(dropOpt)}
                                  >
                                    {dropOpt?.products_options_values_name}
                                    {parseFloat(
                                      dropOpt?.options_values_price
                                    )?.toFixed(2) > 0 &&
                                      dropOpt?.options_values_price && (
                                        <span
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          $
                                          {parseFloat(
                                            dropOpt?.options_values_price
                                          )?.toFixed(2)}
                                        </span>
                                      )}
                                  </Dropdown.Item>
                                </Fragment>
                              );
                            })}
                        </DropdownButton>
                      </div>
                    </div>
                  )}
                  {/* DROPDOWN UI END */}
                </Fragment>
              );
            })}
          {/* THIRD LEVEL MAIN END */}
        </div>

        <div className="nesting-footer place-order-btn">
          <Button
            className="common-btn w-100 add-to-order"
            type="button"
            onClick={() => handelButtonClick(secondLevelData)}
          >
            {renderFourthLevelButtonPriceCount()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FourthLevelBlock;
