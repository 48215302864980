import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  checkAndReturnTotalPrice,
  removeDuplicates,
  renderCheckboxRequiredOpt,
  renderDropdownTitleSecondLevel,
} from "helpers/AddonsModal/AddonsModalHocFunctions";
import { errorToast } from "utils/toastMessage";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GET_SAVE_CART_LOG } from "store/Action/RasturantMenuAction";
import { useDispatch } from "react-redux";

const SecondLevelBlock = ({
  setVisibleSecondLevelBlock,
  secondLevelData,
  addonsObj,
  setAddonsObj,
  setTemp,
  temp,
  setErrorHandler,
  errorHandlerArr,
  RESTAURANTS,
  ADDONS_DETAILS,
  //3rd Level
  showThirdLevelBlock,
  setShowThirdLevelBlock,
  showThirdLevelBlockUi,
  activeThirdLevelBlock,
  handelShowThirdLevelModiefire,
  handelShowThirdLevelModifureOnEvent,
  setActiveNestedMode,
}) => {
  //ALL STATE
  const dispatch = useDispatch();
  const divRefs = useRef([]);
  const handelChangeCheckbox = (
    event,
    firstLevelOptionValue,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    const result = {
      options_id: secondLevelOption?.options_id,
      options_values_id: secondLevelOptionValue?.options_values_id,
      options_values_price: secondLevelOptionValue?.options_values_price,
      // options_value_text: "",
      attributes_max_select: secondLevelOption?.attributes_max_select,
      attributes_min_select: secondLevelOption?.attributes_min_select,
      products_options_name: secondLevelOption?.products_options_name,
      products_options_values_name:
        secondLevelOptionValue?.products_options_values_name,
      quantity: secondLevelOption?.increment_choice_quantity,
      optionValues1: secondLevelOption?.optionValues,
      free_modifier_quantity: secondLevelOption?.free_modifier_quantity,
      attributes_default: secondLevelOptionValue?.attributes_default,
      third_level_attributes: [],
    };
    const newTemp = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (event) {
      newTemp.second_level_attributes.push(result);
      setAddonsObj(addonsObj);
      handelShowThirdLevelModifureOnEvent(
        secondLevelOption,
        secondLevelOptionValue,
        "check",
        true
      );
      setTemp(!temp);
    } else {
      newTemp.second_level_attributes = newTemp.second_level_attributes.filter(
        (el) =>
          el?.options_values_id !== secondLevelOptionValue.options_values_id
      );
      setShowThirdLevelBlock(false);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeRadioSecondLevel = (
    firstLevelOptionValue,
    event,
    secondLevelOption
  ) => {
    const secondLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: secondLevelOption?.options_id,
      options_values_id: secondLevelOptionValue?.options_values_id,
      options_values_price: secondLevelOptionValue?.options_values_price,
      attributes_max_select: secondLevelOption?.attributes_max_select,
      attributes_min_select: secondLevelOption?.attributes_min_select,
      products_options_name: secondLevelOption?.products_options_name,
      products_options_values_name:
        secondLevelOptionValue?.products_options_values_name,
      quantity: secondLevelOptionValue?.quantity,
      optionValues1: secondLevelOption?.optionValues,
      free_modifier_quantity: secondLevelOption?.free_modifier_quantity,
      attributes_default: secondLevelOptionValue?.attributes_default,
      third_level_attributes: [],
    };
    const newTemp = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (
      newTemp?.second_level_attributes?.some(
        (dl) => dl?.options_id == secondLevelOption?.options_id
      )
    ) {
      const pal = newTemp?.second_level_attributes?.filter(
        (el) => el?.options_id !== secondLevelOption?.options_id
      );
      pal.push(result);
      newTemp.second_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp.second_level_attributes.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
    handelShowThirdLevelModifureOnEvent(
      secondLevelOption,
      secondLevelOptionValue,
      "radioType",
      true
    );
  };

  const handelChangeDropdownSecondLevel = (
    firstLevelOptionValue,
    event,
    secondLevelOption
  ) => {
    const secondLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: secondLevelOption?.options_id,
      options_values_id: secondLevelOptionValue?.options_values_id,
      options_values_price: secondLevelOptionValue?.options_values_price,
      attributes_max_select: secondLevelOption?.attributes_max_select,
      attributes_min_select: secondLevelOption?.attributes_min_select,
      products_options_name: secondLevelOption?.products_options_name,
      products_options_values_name:
        secondLevelOptionValue?.products_options_values_name,
      quantity: secondLevelOptionValue?.quantity,
      free_modifier_quantity: secondLevelOption?.free_modifier_quantity,
      optionValues1: secondLevelOption?.optionValues,
      attributes_default: secondLevelOptionValue?.attributes_default,
      third_level_attributes: [],
    };
    const newTemp = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (
      newTemp?.second_level_attributes?.some(
        (dl) => dl?.options_id == secondLevelOption?.options_id
      )
    ) {
      const pal = newTemp?.second_level_attributes?.filter(
        (el) => el?.options_id !== secondLevelOption?.options_id
      );
      pal.push(result);
      newTemp.second_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp?.second_level_attributes?.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeTextSecondLevel = (
    firstLevelOptionValue,
    event,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    setAddonsObj((prevState) => ({
      ...prevState,
      attributes: prevState?.attributes?.map((dy) => {
        if (dy?.options_values_id == firstLevelOptionValue?.options_values_id) {
          return {
            ...dy,
            second_level_attributes: dy?.second_level_attributes?.map((el) => {
              if (
                el?.options_values_id ==
                secondLevelOptionValue?.options_values_id
              ) {
                el.options_value_text = event.target.value;
              }
              return el;
            }),
          };
        } else {
          return dy;
        }
      }),
    }));
    setTemp(!temp);
  };

  const handelChangeQtySecondLevel = (
    event,
    firstLevelOptionValue,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    if (event == "addition") {
      setAddonsObj((prevState) => ({
        ...prevState,
        attributes: prevState?.attributes?.map((dy) => {
          if (
            dy?.options_values_id == firstLevelOptionValue?.options_values_id
          ) {
            return {
              ...dy,
              second_level_attributes: dy?.second_level_attributes?.map(
                (el) => {
                  if (
                    el?.options_values_id ==
                    secondLevelOptionValue?.options_values_id
                  ) {
                    el.quantity =
                      el.quantity + secondLevelOption.increment_choice_quantity;
                  }
                  return { ...el };
                }
              ),
            };
          } else {
            return { ...dy };
          }
        }),
      }));
    } else {
      setAddonsObj((prevState) => ({
        ...prevState,
        attributes: prevState?.attributes?.map((dy) => {
          if (
            dy?.options_values_id == firstLevelOptionValue?.options_values_id
          ) {
            return {
              ...dy,
              second_level_attributes: dy?.second_level_attributes?.map(
                (el) => {
                  if (
                    el?.options_values_id ==
                    secondLevelOptionValue?.options_values_id
                  ) {
                    el.quantity =
                      el.quantity - secondLevelOption.increment_choice_quantity;
                  }
                  return { ...el };
                }
              ),
            };
          } else {
            return { ...dy };
          }
        }),
      }));
    }
  };

  // const handelDisableCheckboxSecondLevel = (data, optionValue) => {
  const handelDisableCheckboxSecondLevel = (
    firstLevelOptionValue,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    const defaultOptionValue = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (
      secondLevelOption?.attributes_min_select == 1 &&
      secondLevelOption?.attributes_max_select == 1
    ) {
      const newTempId = defaultOptionValue?.second_level_attributes?.find(
        (dy) => dy?.options_id == secondLevelOption?.options_id
      );

      if (newTempId) {
        const newTemp = defaultOptionValue?.second_level_attributes?.find(
          (dy) =>
            dy?.options_id == secondLevelOption?.options_id &&
            dy?.options_values_id == secondLevelOptionValue?.options_values_id
        );

        if (newTemp) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else if (
      secondLevelOption?.attributes_min_select == 0 &&
      secondLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.second_level_attributes?.filter(
        (dy) => dy?.options_id == secondLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < secondLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == secondLevelOption?.options_id &&
            dy?.options_values_id == secondLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    } else if (
      secondLevelOption?.attributes_min_select > 0 &&
      secondLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.second_level_attributes?.filter(
        (dy) => dy?.options_id == secondLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < secondLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == secondLevelOption?.options_id &&
            dy?.options_values_id == secondLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const renderSecondLevelButtonPriceCount = () => {
    const firstOptionsIds = [];
    const secondOptionsIds = [];
    const thirdOptionsIds = [];
    const newTemp = addonsObj?.attributes?.find(
      (it) => it.options_values_id == secondLevelData.options_values_id
    );

    let totalPrice = 0;
    let firstOptionPrice = 0;
    let secondOptionPrice = 0;
    let thirdOptionPrice = 0;

    if (newTemp?.second_level_attributes?.length > 0) {
      for (let attribute of newTemp?.second_level_attributes) {
        firstOptionPrice = 0;
        secondOptionPrice = 0;
        thirdOptionPrice = 0;

        if (attribute?.third_level_attributes?.length > 0) {
          for (let attributeNested of attribute?.third_level_attributes) {
            if (attributeNested?.fourth_level_attributes?.length > 0) {
              for (let attributeSecondNested of attributeNested?.fourth_level_attributes) {
                const { price, outerPrice } = checkAndReturnTotalPrice(
                  attributeSecondNested,
                  thirdOptionsIds,
                  thirdOptionPrice
                );
                totalPrice += price;
                thirdOptionPrice = outerPrice;
              }
            }
            totalPrice += thirdOptionPrice;
            const { price, outerPrice } = checkAndReturnTotalPrice(
              attributeNested,
              secondOptionsIds,
              secondOptionPrice
            );
            totalPrice += price;
            secondOptionPrice = outerPrice;
          }
        }
        totalPrice += secondOptionPrice;
        const { price, outerPrice } = checkAndReturnTotalPrice(
          attribute,
          firstOptionsIds,
          firstOptionPrice
        );
        totalPrice += price;
        firstOptionPrice = outerPrice;
        totalPrice += firstOptionPrice;
      }
    }

    return (
      <span>
        <span>
          {totalPrice && !isNaN(totalPrice)
            ? "$" + totalPrice?.toFixed(2)
            : "Add"}
        </span>
      </span>
    );
  };

  // const renderSecondLevelButtonPriceCount = (firstLevelOptionValue) => {
  //   const newTemp = addonsObj?.attributes?.find(
  //     (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
  //   );

  //   if (newTemp !== undefined && newTemp?.length !== 0) {
  //     let totalPrice = 0;
  //     let optionPrice = 0;

  //     const priceGroups = { ...Object.groupBy(
  //       newTemp?.second_level_attributes,
  //       ({ options_id, options_values_price }) =>
  //         `${options_id}#${options_values_price}`
  //     ), ...Object.groupBy(
  //       newTemp?.second_level_attributes,
  //       ({ options_id, options_values_price }) =>
  //         `${options_id}#${options_values_price}`
  //     )};

  //     for (let priceKey in priceGroups) {
  //       const priceGroup = priceGroups[priceKey];
  //       let totalQuantity = 0;
  //       let totalPriceForGroup = 0;
  //       let freeModifierQuantity = 0;

  //       for (let attributeNested of priceGroup) {
  //         totalQuantity += attributeNested?.quantity;
  //         totalPriceForGroup +=
  //           parseFloat(attributeNested?.options_values_price) *
  //           attributeNested?.quantity;
  //         if (attributeNested?.free_modifier_quantity) {
  //           freeModifierQuantity = attributeNested?.free_modifier_quantity;
  //         }
  //       }
  //       console.log("priceGroup", priceGroup, priceGroups)
  //       const isMin =
  //         Math.min(
  //           ...Object.keys(priceGroups)?.map((item) => item?.split("#")[1])
  //         ) === parseFloat(priceGroup?.[0]?.options_values_price);

  //       if (freeModifierQuantity > 0 && isMin) {
  //         let netQty = totalQuantity - freeModifierQuantity;
  //         netQty = netQty > 0 ? netQty : 0;
  //         totalPriceForGroup =
  //           parseFloat(priceGroup?.[0]?.options_values_price) * netQty;
  //         optionPrice += totalPriceForGroup;
  //       } else {
  //         totalPrice += totalPriceForGroup;
  //       }
  //     }

  //     totalPrice += optionPrice;

  //     return (
  //       <span>
  //         <span>{totalPrice ? "$" + totalPrice?.toFixed(2) : "Add"}</span>
  //       </span>
  //     );
  //   }
  // };

  const handleButtonClick = () => {
    let selectedOptions = addonsObj?.attributes?.find(
      (rr) => rr?.options_values_id == secondLevelData?.options_values_id
    )?.second_level_attributes;

    const newSecondLevel = ADDONS_DETAILS?.find(
      (ty) => ty?.options_id == secondLevelData?.options_id
    )?.optionValues?.find(
      (item) => item.options_values_id === secondLevelData?.options_values_id
    )?.second_level_options;

    const allOptionIds = [];
    const allSelectedOptionIds = [];
    const selectedArray = [];
    //return;
    selectedOptions?.map((slo) => {
      selectedArray.push(slo);
    });

    newSecondLevel?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });

    /* Code by ss */
    let anyError = false;
    let errorMessage = "";
    selectedOptions.forEach((attribute) => {
      const optionsValuesId = attribute.options_values_id;
      newSecondLevel.forEach((addon) => {
        const option = addon.optionValues.find(
          (option) => option.options_values_id === optionsValuesId
        );
        if (typeof option !== "undefined") {
          option?.third_level_options?.forEach((thirdLevelOption) => {
            if (
              thirdLevelOption.attributes_min_select > 0 &&
              attribute.third_level_attributes.length <
                thirdLevelOption.attributes_min_select
            ) {
              errorMessage =
                "Please select inner item of " +
                option?.products_options_values_name;
              anyError = true;
              return;
            }
          });
          if (anyError) return;
          attribute?.third_level_attributes?.map((third_attribute) => {
            option?.third_level_options?.forEach((thirdLevelOption) => {
              const third_option = thirdLevelOption.optionValues.find(
                (option) =>
                  option.options_values_id === third_attribute.options_values_id
              );
              if (typeof third_option !== "undefined") {
                third_option?.fourth_level_options?.forEach(
                  (fourthLevelOption) => {
                    if (
                      fourthLevelOption.attributes_min_select > 0 &&
                      third_attribute?.fourth_level_attributes?.length <
                        fourthLevelOption.attributes_min_select
                    ) {
                      errorMessage =
                        "Please select inner item of " +
                        third_option?.products_options_values_name;
                      anyError = true;
                      return;
                    }
                  }
                );
                if (anyError) return;
              }
            });
          });
        }
      });
    });
    if (anyError) {
      errorToast(errorMessage);
      return;
    }
    // ********************

    for (let addon of newSecondLevel) {
      if (
        addon.products_options_type === 2 ||
        addon.products_options_type === 3
      ) {
        let optionsId = addon.options_id;
        if (addon.attributes_min_select > 0) {
          let selectedAttributes = selectedOptions.filter(
            (att) => att.options_id === optionsId
          );
          if (addon.attributes_min_select > selectedAttributes.length) {
            const errorMessage = `Minimum  ${addon.attributes_min_select}
              quantity is required for ${addon.products_options_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              // addon_details: newthirdLevel,
              // selectedAddon: selectedOptions,
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );

            anyError = true;
            break;
          }

          if (
            addon.attributes_max_select !== 0 &&
            addon.attributes_max_select < selectedAttributes.length
          ) {
            const errorMessage = `Maximum  ${addon.attributes_max_select}
              quantity can be choose for ${addon.products_options_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );
            anyError = true;
            break;
          }
        } else {
          const selectedAttributes = selectedOptions.find(
            (att) => att.options_id === optionsId
          );
          const selectedParticularAttributes =
            selectedAttributes?.optionValues1?.find(
              (it) =>
                it?.options_values_id === selectedAttributes?.options_values_id
            );

          const attributeDetails =
            selectedParticularAttributes?.third_level_options?.find(
              (it) => it?.attributes_min_select
            );

          if (
            attributeDetails?.attributes_min_select >
            selectedAttributes?.third_level_attributes?.filter(
              (it) => it.options_id === attributeDetails?.options_id
            ).length
          ) {
            const errorMessage = `Please select inner item of ${selectedParticularAttributes?.products_options_values_name}`;
            errorToast(errorMessage);

            const errorPayload = {
              addon_details: ADDONS_DETAILS,
              selectedAddon: addonsObj,
              errorMessage,
            };
            dispatch(
              GET_SAVE_CART_LOG(
                errorPayload,
                (res, otherRes, loading, error) => {}
              )
            );

            anyError = true;
            break;
          }
        }
        let totalQtySelected = 0;
        selectedOptions.forEach((attribute) => {
          if (attribute.options_id === optionsId) {
            totalQtySelected += attribute.quantity;
          }
        });

        if (
          addon?.support_choice_quantities &&
          totalQtySelected < addon.min_aggregate_quantity /* &&
          addon.attributes_min_select > 0 */
        ) {
          const errorMessage = `Minimum ${addon.min_aggregate_quantity}  quantity is required for ${addon.products_options_name}`;
          errorToast(errorMessage);
          const errorPayload = {
            addon_details: ADDONS_DETAILS,
            selectedAddon: addonsObj,
            errorMessage,
          };
          dispatch(
            GET_SAVE_CART_LOG(
              errorPayload,
              (res, otherRes, loading, error) => {}
            )
          );
          anyError = true;
          return;
        }
        //return;

        if (
          addon?.support_choice_quantities &&
          addon.max_aggregate_quantity !== 0 &&
          totalQtySelected > addon.max_aggregate_quantity
        ) {
          const errorMessage = `Maximum ${addon.max_aggregate_quantity} quantity can be choose for ${addon.products_options_name}`;
          errorToast(errorMessage);
          const errorPayload = {
            addon_details: ADDONS_DETAILS,
            selectedAddon: addonsObj,
            errorMessage,
          };
          dispatch(
            GET_SAVE_CART_LOG(
              errorPayload,
              (res, otherRes, loading, error) => {}
            )
          );
          anyError = true;
          return;
        }
      }
    }

    if (anyError) {
      return;
    }

    const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds = removeIds?.filter((ee) => {
      const newTemp = selectedArray?.find((et) => et?.options_id == ee);
      if (
        !(
          newTemp?.attributes_min_select <=
          allSelectedOptionIds?.filter((rt) => rt == ee).length
        )
      ) {
        return ee;
      }
    });
    setErrorHandler(removeIds);
    setTemp(!temp);
    if (removeIds.length == 0) {
      const index = addonsObj?.attributes?.findIndex(
        (rr) => rr?.options_values_id == secondLevelData?.options_values_id
      );
      if (index !== -1)
        addonsObj.attributes[index].second_level_attributes =
          selectedOptions?.map((it) => {
            return {
              ...it,
              isAdd: true,
            };
          });
      setAddonsObj(addonsObj);
      setActiveNestedMode("firstLevel");
      setShowThirdLevelBlock(false);
      setVisibleSecondLevelBlock(false);
    } else {
      if (divRefs) {
        const targetRef = divRefs.current.find(
          (ref) => ref?.id == removeIds[0]
        );
        if (targetRef) {
          targetRef.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  // const handleButtonClick = (firstLevelOptionValue) => {
  //   let selectedOptions = addonsObj?.attributes?.find(
  //     (rr) => rr?.options_values_id == firstLevelOptionValue?.options_values_id
  //   );

  //   const allOptionIds = [];
  //   const allSelectedOptionIds = [];
  //   const selectedArray = [];
  //   selectedOptions?.second_level_attributes?.map((slo) => {
  //     selectedArray.push(slo);
  //   });
  //   firstLevelOptionValue?.second_level_options?.map((slo) => {
  //     if (slo?.attributes_min_select > 0) {
  //       allOptionIds.push(slo.options_id);
  //     }
  //   });
  //   selectedOptions?.second_level_attributes?.map((slo) => {
  //     if (slo?.attributes_min_select > 0) {
  //       allSelectedOptionIds.push(slo.options_id);
  //     }
  //   });

  //   const addOn = { ...addonsObj };
  //   const index = addOn?.attributes?.findIndex(
  //     (rr) => rr?.options_values_id == firstLevelOptionValue?.options_values_id
  //   );
  //   if (index !== -1)
  //     addOn.attributes[index].second_level_attributes =
  //       selectedOptions.second_level_attributes?.map((it) => {
  //         // if (it.attributes_default === 0) {
  //         // it.isAdd = true;
  //         // }
  //         return {
  //           ...it,
  //           isAdd: true,
  //         };
  //       });
  //   setAddonsObj(addOn);

  //   let secondLevelArr = selectedArray?.reduce((accumulator, currentItem) => {
  //     const index = accumulator.findIndex(
  //       (item) => item?.options_id == currentItem?.options_id
  //     );
  //     if (index !== -1) {
  //       accumulator[index].quantity += currentItem.quantity;
  //     } else {
  //       const option = firstLevelOptionValue?.second_level_options?.find(
  //         (item) => item?.options_id === currentItem?.options_id
  //       );
  //       if (
  //         option?.min_aggregate_quantity != 0 ||
  //         option?.max_aggregate_quantity !== 0
  //       ) {
  //         accumulator.push({
  //           options_id: currentItem.options_id,
  //           quantity: currentItem.quantity,
  //           min: option?.min_aggregate_quantity,
  //           max: option?.max_aggregate_quantity,
  //           product_name: option?.products_options_name,
  //         });
  //       }
  //     }
  //     return accumulator;
  //   }, []);
  //   let obj = { success: false, msg: "" };
  //   for (let newTemp of secondLevelArr) {
  //     if (newTemp?.quantity > newTemp?.max) {
  //       obj = {
  //         success: true,
  //         msg: `Maximum ${newTemp?.max} quantity can be choose for ${newTemp?.product_name}`,
  //       };
  //       break;
  //     }
  //     if (newTemp?.quantity < newTemp?.min) {
  //       obj = {
  //         success: true,
  //         msg: `Minimum ${newTemp?.min} quantity can be choose for ${newTemp?.product_name}`,
  //       };
  //       break;
  //     }
  //   }

  //   if (obj?.success) {
  //     errorToast(obj?.msg);
  //     return null;
  //   }

  //   const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
  //   let removeIds = removeDuplicates(resultIdsArr);
  //   removeIds = removeIds?.filter((ee) => {
  //     const newTemp = selectedArray?.find((et) => et?.options_id == ee);
  //     if (
  //       !(
  //         newTemp?.attributes_min_select <=
  //         allSelectedOptionIds?.filter((rt) => rt == ee).length
  //       )
  //     ) {
  //       return ee;
  //     }
  //   });
  //   setErrorHandler(removeIds);
  //   setTemp(!temp);
  //   if (removeIds.length == 0) {
  //     setActiveNestedMode("firstLevel");
  //     setVisibleSecondLevelBlock(false);
  //   } else {
  //     if (divRefs) {
  //       const targetRef = divRefs.current.find(
  //         (ref) => ref?.id == removeIds[0]
  //       );
  //       if (targetRef) {
  //         targetRef.scrollIntoView({ behavior: "smooth" });
  //       }
  //     }
  //     //errorToast("Please select item");
  //   }
  // };

  const handleBackTrigger = (firstLevelOptionValue) => {
    let selectedOptions = addonsObj?.attributes?.find(
      (rr) => rr?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    const selectedArray = [];
    const allOptionIds = [];
    const allSelectedOptionIds = [];
    firstLevelOptionValue?.second_level_options?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.second_level_attributes?.map((slo) => {
      selectedArray.push(slo);

      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });
    const resultIdsArr = [
      ...allOptionIds,
      ...selectedArray?.map((it) => it?.options_id),
    ];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds?.map((ee) => {
      const addOn = { ...addonsObj };
      const firstLevel = ADDONS_DETAILS?.find(
        (rr) => rr?.options_id == selectedOptions?.options_id
      )?.optionValues?.find(
        (rr) => rr?.options_values_id == selectedOptions?.options_values_id
      );
      const index = addOn?.attributes?.findIndex(
        (rr) =>
          rr?.options_values_id == firstLevelOptionValue?.options_values_id
      );
      if (index !== -1) {
        const arr = firstLevel.second_level_options
          .map((it) =>
            it.optionValues
              ?.filter((el) => el.attributes_default > 0)
              ?.map((item) => ({
                ...item,
                products_options_name: it?.products_options_name,
                third_level_attributes: [],
              }))
          )
          .flat();
        const selected = selectedOptions?.second_level_attributes?.filter(
          (item) =>
            arr.some(
              (it) =>
                (it.options_values_id === item.options_values_id &&
                  item?.isAdd) ||
                it.attributes_default > 0
            )
        );
        const valueSelected = selected.length > 0 ? selected : arr;
        addOn.attributes[index].second_level_attributes = [
          ...selectedOptions?.second_level_attributes?.filter(
            (it) => it.isAdd && it.attributes_default === 0
          ),
          ...valueSelected,
        ];
      }

      setAddonsObj(addOn);
    });
    let secondLevelArr = selectedArray?.reduce((accumulator, currentItem) => {
      const index = accumulator.findIndex(
        (item) => item.options_id == currentItem.options_id
      );
      if (index !== -1) {
        accumulator[index].quantity += currentItem.quantity;
      } else {
        const option = firstLevelOptionValue?.second_level_options?.find(
          (item) => item.options_id === currentItem.options_id
        );
        if (
          option?.min_aggregate_quantity != 0 ||
          option?.min_aggregate_quantity != 0
        ) {
          accumulator.push({
            options_id: currentItem.options_id,
            quantity: currentItem.quantity,
            min: option?.min_aggregate_quantity,
            max: option?.max_aggregate_quantity,
            product_name: option?.products_options_name,
          });
        }
      }
      return accumulator;
    }, []);
    let obj = { success: false, msg: "" };
    for (let newTemp of secondLevelArr) {
      if (newTemp?.quantity > newTemp?.max) {
        obj = {
          success: true,
          msg: `Maximum ${newTemp?.max} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
      if (newTemp?.quantity < newTemp?.min) {
        obj = {
          success: true,
          msg: `Minimum ${newTemp?.min} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
    }

    if (obj?.success) {
      errorToast(obj?.msg);
      return null;
    }
    setActiveNestedMode("firstLevel");
    setShowThirdLevelBlock(false);
    setVisibleSecondLevelBlock(false);
  };

  const renderSelectionUI = (optionValue) => {
    const newTempp = addonsObj?.attributes
      ?.find(
        (rt) => rt?.options_values_id == secondLevelData?.options_values_id
      )
      ?.second_level_attributes?.find(
        (rt) => rt?.options_values_id == optionValue?.options_values_id
      );

    if (newTempp && Object?.keys(newTempp)?.length) {
      const newArr = {};
      newTempp?.third_level_attributes?.map((ry) => {
        if (newArr?.hasOwnProperty(ry?.options_id)) {
          newArr[ry?.options_id].options_value_name.push(
            RESTAURANTS?.is_display_modifier_quantity === 1
              ? ry?.quantity + " x " + ry?.products_options_values_name
              : ry?.products_options_values_name
          );
        } else {
          newArr[ry?.options_id] = {};
          newArr[ry?.options_id] = {
            option_name: ry?.products_options_name,
            options_value_name: [
              RESTAURANTS?.is_display_modifier_quantity === 1
                ? ry?.quantity + " x " + ry?.products_options_values_name
                : ry?.products_options_values_name,
            ],
            options_value_text: ry?.options_value_text,
            second_level_attributes: {},
          };
          if (ry?.fourth_level_attributes?.length) {
            ry?.fourth_level_attributes?.map((ey) => {
              const secondLevel =
                newArr[ry?.options_id].second_level_attributes;
              if (secondLevel?.hasOwnProperty(ey?.options_id)) {
                secondLevel[ey?.options_id].options_value_name.push(
                  RESTAURANTS?.is_display_modifier_quantity === 1
                    ? ey?.quantity + " x " + ey?.products_options_values_name
                    : ey?.products_options_values_name
                );
              } else {
                secondLevel[ey?.options_id] = {
                  option_name: ey?.products_options_name,
                  options_value_name: [
                    RESTAURANTS?.is_display_modifier_quantity === 1
                      ? ey?.quantity + " x " + ey?.products_options_values_name
                      : ey?.products_options_values_name,
                  ],
                  options_value_text: ey?.options_value_text,
                };
              }
            });
          }
        }
      });

      return Object.keys(newArr)?.map((tt, index) => {
        const textValue = newArr[tt]?.options_value_name;
        const textName = newArr[tt]?.option_name;
        const specialName = newArr[tt]?.options_value_text;

        return textName != "Special Instructions" ? (
          <>
            <div className="select-item border-bottom pb-2 mb-2" key={index}>
              <span className="fw-bold">{textName}</span>
              <small>{textValue?.join(", ")}</small>
            </div>
            {Object.keys(newArr[tt]?.second_level_attributes)?.map(
              (dy, index2) => {
                const textValue =
                  newArr[tt]?.second_level_attributes?.[dy]?.options_value_name;
                const textName =
                  newArr[tt]?.second_level_attributes?.[dy]?.option_name;
                const specialName =
                  newArr[tt]?.second_level_attributes?.[dy]?.options_value_text;
                return textName != "Special Instructions" ? (
                  <>
                    <div
                      className="select-item border-bottom pb-2 mb-2"
                      key={index2}
                    >
                      <span className="fw-bold">{textName}</span>
                      <small>{textValue?.join(", ")}</small>
                    </div>
                  </>
                ) : specialName ? (
                  <div
                    className="select-item border-bottom pb-2 mb-2"
                    key={index2}
                  >
                    <span className="fw-bold">{textName}</span>
                    <small>{specialName}</small>
                  </div>
                ) : null;
              }
            )}
          </>
        ) : specialName ? (
          <div className="select-item border-bottom pb-2 mb-2" key={index}>
            <span className="fw-bold">{textName}</span>
            <small>{specialName}</small>
          </div>
        ) : null;
      });
    }
  };

  return (
    <div>
      <div className="nesting-modal">
        <h4 className="ps-md-4 ps-3">
          <Button className="bg-transparent border-0 text-dark ps-0">
            <ArrowBackIcon onClick={() => handleBackTrigger(secondLevelData)} />
            {/* <ArrowBackIcon onClick={() => setVisibleSecondLevelBlock(false)} /> */}
          </Button>
          {secondLevelData?.products_options_values_name ?? ""}
        </h4>

        <div className="nesting-wrapper">
          {/* SECOND LEVEL MAIN START */}
          {secondLevelData?.second_level_options &&
            secondLevelData?.second_level_options?.length !== 0 &&
            secondLevelData?.second_level_options?.map((sec, index) => {
              return (
                <Fragment key={sec?.options_id}>
                  {/* CHECKBOX UI START */}
                  {sec?.products_options_type == 3 && (
                    <Fragment>
                      <div className="choose-option mb-2">
                        <div className="choose-option-inner">
                          {" "}
                          <h5
                            id={sec?.options_id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            {sec?.products_options_name}&nbsp;
                            {sec?.free_modifier_quantity > 0 && (
                              <span style={{ fontSize: "13px" }}>
                                ({sec?.free_modifier_quantity} Free option
                                available)
                              </span>
                            )}
                            {errorHandlerArr?.length > 0 &&
                            errorHandlerArr.includes(sec?.options_id) ? (
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                }}
                              >
                                {"(Please select item)"}
                              </span>
                            ) : null}
                          </h5>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(sec)}
                          </p>
                        </div>

                        {sec?.optionValues &&
                          sec?.optionValues?.length !== 0 &&
                          sec?.optionValues?.map((checkopt, ind) => {
                            return (
                              <FormGroup key={checkopt?.options_values_id}>
                                <div className="nested-wrapper-sec d-flex justify-content-between align-items-center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: "",
                                          "&.Mui-checked": {
                                            color: "#F06F00",
                                          },
                                        }}
                                        checked={addonsObj?.attributes
                                          ?.find(
                                            (ft) =>
                                              ft?.options_values_id ==
                                              secondLevelData?.options_values_id
                                          )
                                          ?.second_level_attributes?.some(
                                            (el) =>
                                              el?.options_values_id ==
                                              checkopt?.options_values_id
                                                ? true
                                                : false
                                          )}
                                        onChange={(e) =>
                                          handelChangeCheckbox(
                                            e.target.checked,
                                            secondLevelData,
                                            sec,
                                            checkopt
                                          )
                                        }
                                        disabled={handelDisableCheckboxSecondLevel(
                                          secondLevelData,
                                          sec,
                                          checkopt
                                        )}
                                      />
                                    }
                                    // label={checkopt?.products_options_values_name}
                                    label={
                                      <>
                                        <div className="d-flex justify-content-between w-100 salad-wrapper">
                                          <span>
                                            {
                                              checkopt?.products_options_values_name
                                            }
                                          </span>
                                        </div>
                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                          1 &&
                                          parseFloat(
                                            checkopt?.options_values_price
                                          )?.toFixed(2) > 0 && (
                                            <span className="price fw-semibold">
                                              $
                                              {parseFloat(
                                                checkopt?.options_values_price
                                              )?.toFixed(2)}
                                            </span>
                                          )}
                                      </>
                                    }
                                    className="w-100"
                                  />
                                  <div className="d-flex align-items-center nested-price-wrapper">
                                    {/* <span className="price">
                                      $
                                      {parseFloat(
                                        checkopt?.options_values_price
                                      )?.toFixed(2)}
                                    </span> */}

                                    {RESTAURANTS?.is_display_modifier_quantity ===
                                      1 && sec?.support_choice_quantities ? (
                                      <div className="qty-wrap">
                                        <button
                                          type="button"
                                          disabled={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? false
                                              : true
                                          }
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.find(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    checkopt?.options_values_id
                                                )?.quantity >
                                              checkopt.min_choice_quantity
                                            ) {
                                              handelChangeQtySecondLevel(
                                                "subtract",
                                                secondLevelData,
                                                sec,
                                                checkopt
                                              );
                                            }
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </button>

                                        <span
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? "qty-count"
                                              : "qty-count qty-disable"
                                          }
                                        >
                                          {addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.find(
                                              (el) =>
                                                el?.options_values_id ==
                                                checkopt?.options_values_id
                                            )?.quantity ?? 0}
                                        </span>
                                        <button
                                          type="button"
                                          disabled={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? false
                                              : true
                                          }
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              checkopt.max_choice_quantity ===
                                                0 ||
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.find(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    checkopt?.options_values_id
                                                )?.quantity <
                                                checkopt.max_choice_quantity
                                            ) {
                                              handelChangeQtySecondLevel(
                                                "addition",
                                                secondLevelData,
                                                sec,
                                                checkopt
                                              );
                                            }
                                          }}
                                        >
                                          <ControlPointOutlinedIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(
                                          checkopt?.options_values_price
                                        )?.toFixed(2) > 0 ? (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              checkopt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                    {/* QUANTITY OF CHECKBOX */}
                                    {checkopt?.third_level_options &&
                                    checkopt?.third_level_options?.length !==
                                      0 ? (
                                      <>
                                        {showThirdLevelBlock &&
                                        activeThirdLevelBlock?.products_attributes_id ==
                                          checkopt?.products_attributes_id ? (
                                          <ExpandLessIcon
                                            onClick={() => {
                                              if (
                                                addonsObj?.attributes
                                                  ?.find(
                                                    (ft) =>
                                                      ft?.options_values_id ==
                                                      secondLevelData?.options_values_id
                                                  )
                                                  ?.second_level_attributes?.some(
                                                    (el) =>
                                                      el?.options_values_id ==
                                                      checkopt?.options_values_id
                                                  )
                                              ) {
                                                handelShowThirdLevelModiefire(
                                                  sec,
                                                  checkopt,
                                                  "check",
                                                  false
                                                );
                                              }
                                            }}
                                            style={
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.some(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    checkopt?.options_values_id
                                                )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        ) : (
                                          <ExpandMoreIcon
                                            onClick={() => {
                                              if (
                                                addonsObj?.attributes
                                                  ?.find(
                                                    (ft) =>
                                                      ft?.options_values_id ==
                                                      secondLevelData?.options_values_id
                                                  )
                                                  ?.second_level_attributes?.some(
                                                    (el) =>
                                                      el?.options_values_id ==
                                                      checkopt?.options_values_id
                                                  )
                                              ) {
                                                handelShowThirdLevelModiefire(
                                                  sec,
                                                  checkopt,
                                                  "check",
                                                  true
                                                );
                                              }
                                            }}
                                            style={
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.some(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    checkopt?.options_values_id
                                                )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <span
                                        style={{
                                          width: "15px",
                                          marginLeft: "18px",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                {checkopt?.third_level_options &&
                                  checkopt?.third_level_options?.length !==
                                    0 && (
                                    <>
                                      {showThirdLevelBlock &&
                                        activeThirdLevelBlock?.products_attributes_id ==
                                          checkopt?.products_attributes_id && (
                                          <Fragment>
                                            <div className="selection-required">
                                              {renderSelectionUI(checkopt)}
                                              <div
                                                className="d-flex justify-content-between edit-selection"
                                                onClick={() =>
                                                  showThirdLevelBlockUi(
                                                    sec,
                                                    checkopt
                                                  )
                                                }
                                              >
                                                <h6 className="mb-0 text-decoration-underline">
                                                  Add selections
                                                </h6>
                                                <ArrowForwardIosIcon />
                                              </div>
                                            </div>
                                          </Fragment>
                                        )}
                                    </>
                                  )}
                                {/* QUANTITY OF CHECKBOX */}
                              </FormGroup>
                            );
                          })}
                      </div>
                    </Fragment>
                  )}
                  {/* CHECKBOX UI END */}

                  {/* RADIO UI START */}
                  {sec?.products_options_type == 2 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner 123">
                        {" "}
                        <h5
                          id={sec?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {sec?.products_options_name}{" "}
                          {sec?.free_modifier_quantity > 0 && (
                            <span style={{ fontSize: "13px" }}>
                              ({sec?.free_modifier_quantity} Free option
                              available)
                            </span>
                          )}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(sec?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(sec)}
                          </p>
                        </h5>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      {/* <FormControl> */}
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue=""

                        // name="radio-buttons-group"
                        onChange={(e) =>
                          handelChangeRadioSecondLevel(
                            secondLevelData,
                            e.target.value,
                            sec
                          )
                        }
                      >
                        {sec?.optionValues &&
                          sec?.optionValues?.length !== 0 &&
                          sec?.optionValues?.map((radioOpt) => {
                            return (
                              <Fragment key={radioOpt?.options_values_id}>
                                <div className="nested-wrapper-sec d-flex justify-content-between align-items-center">
                                  <FormControlLabel
                                    value={JSON.stringify(radioOpt)}
                                    control={
                                      <Radio
                                        checked={addonsObj?.attributes
                                          ?.find(
                                            (ft) =>
                                              ft?.options_values_id ==
                                              secondLevelData?.options_values_id
                                          )
                                          ?.second_level_attributes?.some(
                                            (el) =>
                                              el?.options_values_id ==
                                              radioOpt?.options_values_id
                                                ? true
                                                : false
                                          )}
                                        sx={{
                                          color: "",
                                          "&.Mui-checked": {
                                            color: "#F06F00",
                                          },
                                        }}
                                      />
                                    }
                                    // label={radioOpt?.products_options_values_name}
                                    label={
                                      <>
                                        <div className="d-flex justify-content-between w-100 salad-wrapper">
                                          <span>
                                            {
                                              radioOpt?.products_options_values_name
                                            }
                                          </span>
                                        </div>
                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                          1 &&
                                          parseFloat(
                                            radioOpt?.options_values_price
                                          )?.toFixed(2) > 0 && (
                                            <span className="price">
                                              $
                                              {parseFloat(
                                                radioOpt?.options_values_price
                                              )?.toFixed(2)}
                                            </span>
                                          )}
                                      </>
                                    }
                                  />
                                  <div className="d-flex align-items-center nested-price-wrapper">
                                    {/* <span className="price">
                                    $
                                    {parseFloat(
                                      radioOpt?.options_values_price
                                    )?.toFixed(2)}
                                  </span> */}
                                    {RESTAURANTS?.is_display_modifier_quantity ===
                                      1 && sec?.support_choice_quantities ? (
                                      <div className="qty-wrap">
                                        <button
                                          type="button"
                                          disabled={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  radioOpt?.options_values_id
                                              )
                                              ? false
                                              : true
                                          }
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  radioOpt?.options_values_id
                                              )
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.find(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    radioOpt?.options_values_id
                                                )?.quantity >
                                              radioOpt.min_choice_quantity
                                            ) {
                                              handelChangeQtySecondLevel(
                                                "subtract",
                                                secondLevelData,
                                                sec,
                                                radioOpt
                                              );
                                            }
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </button>

                                        <span
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  radioOpt?.options_values_id
                                              )
                                              ? "qty-count"
                                              : "qty-count qty-disable"
                                          }
                                        >
                                          {addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.find(
                                              (el) =>
                                                el?.options_values_id ==
                                                radioOpt?.options_values_id
                                            )?.quantity ?? 0}
                                        </span>
                                        <button
                                          type="button"
                                          disabled={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  radioOpt?.options_values_id
                                              )
                                              ? false
                                              : true
                                          }
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  radioOpt?.options_values_id
                                              )
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              radioOpt.max_choice_quantity ===
                                                0 ||
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.find(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    radioOpt?.options_values_id
                                                )?.quantity <
                                                radioOpt.max_choice_quantity
                                            ) {
                                              handelChangeQtySecondLevel(
                                                "addition",
                                                secondLevelData,
                                                sec,
                                                radioOpt
                                              );
                                            }
                                          }}
                                        >
                                          <ControlPointOutlinedIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(
                                          radioOpt?.options_values_price
                                        )?.toFixed(2) > 0 ? (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              radioOpt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                    {/* QUANTITY OF CHECKBOX */}
                                    {radioOpt?.third_level_options &&
                                    radioOpt?.third_level_options?.length !==
                                      0 ? (
                                      <>
                                        {showThirdLevelBlock &&
                                        activeThirdLevelBlock?.products_attributes_id ==
                                          radioOpt?.products_attributes_id ? (
                                          <ExpandLessIcon
                                            onClick={() => {
                                              if (
                                                addonsObj?.attributes
                                                  ?.find(
                                                    (ft) =>
                                                      ft?.options_values_id ==
                                                      secondLevelData?.options_values_id
                                                  )
                                                  ?.second_level_attributes?.some(
                                                    (el) =>
                                                      el?.options_values_id ==
                                                      radioOpt?.options_values_id
                                                  )
                                              ) {
                                                handelShowThirdLevelModiefire(
                                                  sec,
                                                  radioOpt,
                                                  "check",
                                                  false
                                                );
                                              }
                                            }}
                                            style={
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.some(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    radioOpt?.options_values_id
                                                )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        ) : (
                                          <ExpandMoreIcon
                                            onClick={() => {
                                              if (
                                                addonsObj?.attributes
                                                  ?.find(
                                                    (ft) =>
                                                      ft?.options_values_id ==
                                                      secondLevelData?.options_values_id
                                                  )
                                                  ?.second_level_attributes?.some(
                                                    (el) =>
                                                      el?.options_values_id ==
                                                      radioOpt?.options_values_id
                                                  )
                                              ) {
                                                handelShowThirdLevelModiefire(
                                                  sec,
                                                  radioOpt,
                                                  "check",
                                                  true
                                                );
                                              }
                                            }}
                                            style={
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.some(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    radioOpt?.options_values_id
                                                )
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    color: "lightGray",
                                                    cursor: "not-allowed",
                                                  }
                                            }
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <span
                                        style={{
                                          width: "15px",
                                          marginLeft: "18px",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                {radioOpt?.third_level_options &&
                                  radioOpt?.third_level_options?.length !==
                                    0 && (
                                    <>
                                      {showThirdLevelBlock &&
                                        activeThirdLevelBlock?.products_attributes_id ==
                                          radioOpt?.products_attributes_id && (
                                          <Fragment>
                                            <div className="selection-required">
                                              {renderSelectionUI(radioOpt)}
                                              <div
                                                className="d-flex justify-content-between edit-selection"
                                                onClick={() =>
                                                  showThirdLevelBlockUi(
                                                    sec,
                                                    radioOpt
                                                  )
                                                }
                                              >
                                                <h6 className="mb-0 text-decoration-underline">
                                                  Add selections
                                                </h6>
                                                <ArrowForwardIosIcon />
                                              </div>
                                            </div>
                                          </Fragment>
                                        )}
                                    </>
                                  )}
                                {/* QUANTITY OF CHECKBOX */}
                              </Fragment>
                            );
                          })}
                      </RadioGroup>
                      {/* </FormControl> */}
                    </div>
                  )}
                  {/* RADIO UI START */}

                  {/* TEXT UI START */}
                  {sec?.products_options_type == 1 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={sec?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {sec?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(sec?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#000",
                            marginBottom: "0px",
                          }}
                        >
                          (additional charges may apply)
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(sec)}
                        </p>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      {sec?.optionValues &&
                        sec?.optionValues?.length !== 0 &&
                        sec?.optionValues?.map((textOpt) => {
                          return (
                            <Fragment key={textOpt?.options_values_id}>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="my-3"
                                placeholder="Add Special instructions"
                                style={{ resize: "none" }}
                                onChange={(e) =>
                                  handelChangeTextSecondLevel(
                                    secondLevelData,
                                    e,
                                    sec,
                                    textOpt
                                  )
                                }
                                value={
                                  addonsObj?.attributes
                                    ?.find(
                                      (ft) =>
                                        ft?.options_values_id ==
                                        secondLevelData?.options_values_id
                                    )
                                    ?.second_level_attributes?.find(
                                      (el) =>
                                        el?.options_values_id ==
                                        textOpt?.options_values_id
                                    )?.options_value_text
                                }
                              />
                            </Fragment>
                          );
                        })}
                    </div>
                  )}
                  {/* TEXT UI END  */}

                  {/* DROPDOWN UI START */}
                  {sec?.products_options_type == 0 && (
                    <div className="choose-option mb-2 build-own-li">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={sec?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {sec?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(sec?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(sec)}
                        </p>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      <div className="product-data-sec">
                        <DropdownButton
                          className="form-control p-0"
                          title={
                            <h6 className="d-flex justify-content-between w-100 pe-3 mb-0 h-100 align-items-center ">
                              {/* <span>test</span> <span>$000</span> */}
                              {renderDropdownTitleSecondLevel(
                                secondLevelData,
                                sec,
                                addonsObj
                              )}
                            </h6>
                          }
                          id="dropdown-menu-align-right"
                          onSelect={(event) =>
                            handelChangeDropdownSecondLevel(
                              secondLevelData,
                              event,
                              sec
                            )
                          }
                        >
                          {sec?.optionValues &&
                            sec?.optionValues?.length !== 0 &&
                            sec?.optionValues?.map((dropOpt) => {
                              return (
                                <Fragment key={dropOpt?.products_attributes_id}>
                                  <Dropdown.Item
                                    eventKey={JSON.stringify(dropOpt)}
                                    active={addonsObj?.attributes
                                      ?.find(
                                        (ft) =>
                                          ft?.options_values_id ==
                                          secondLevelData?.options_values_id
                                      )
                                      ?.second_level_attributes?.some((el) =>
                                        el?.options_values_id ==
                                        dropOpt?.options_values_id
                                          ? true
                                          : false
                                      )}
                                  >
                                    {dropOpt?.products_options_values_name}
                                    {parseFloat(
                                      dropOpt?.options_values_price
                                    )?.toFixed(2) > 0 &&
                                      dropOpt?.options_values_price && (
                                        <span
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          $
                                          {parseFloat(
                                            dropOpt?.options_values_price
                                          )?.toFixed(2)}
                                        </span>
                                      )}
                                  </Dropdown.Item>
                                </Fragment>
                              );
                            })}
                        </DropdownButton>
                      </div>
                    </div>
                  )}
                  {/* DROPDOWN UI END */}
                </Fragment>
              );
            })}
          {/* SECOND LEVEL MAIN END */}
        </div>

        <div className="nesting-footer place-order-btn">
          <Button
            className="common-btn w-100 add-to-order"
            type="button"
            // onClick={() => secondLevelSubmitButton(secondLevelData)}
            onClick={() => handleButtonClick(secondLevelData)}
          >
            {renderSecondLevelButtonPriceCount(secondLevelData)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SecondLevelBlock;
