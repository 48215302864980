const GET_LOGIN = (data, callback) => {
  return {
    type: "GET_LOGIN",
    payload: data,
    callback,
  };
};

const SET_LOGIN = (data) => {
  return {
    type: "SET_LOGIN",
    payload: data,
  };
};

const GET_FORGOT_PASSWORD = (data, callback) => {
  return {
    type: "GET_FORGOT_PASSWORD",
    payload: data,
    callback,
  };
};

const SET_FORGOT_PASSWORD = (data) => {
  return {
    type: "SET_FORGOT_PASSWORD",
    payload: data,
  };
};

const GET_OTP_VERIFY = (data, callback) => {
  return {
    type: "GET_OTP_VERIFY",
    payload: data,
    callback,
  };
};
const SET_OTP_VERIFY = (data, callback) => {
  return {
    type: "SET_OTP_VERIFY",
    payload: data,
    callback,
  };
};
const GET_RESET_PASSWORD = (data, callback) => {
  return {
    type: "GET_RESET_PASSWORD",
    payload: data,
    callback,
  };
};
const GET_GENERATE_OTP = (data, page, callback) => {
  return {
    type: "GET_GENERATE_OTP",
    payload: data,
    page: page,
    callback,
  };
};

const GET_USER_VERIFY = (data, callback) => {
  return {
    type: "GET_USER_VERIFY",
    payload: data,
    callback,
  };
};

const GET_SIGN_UP_DETAILS = (data, callback) => {
  return {
    type: "GET_SIGN_UP_DETAILS",
    payload: data,
    callback,
  };
};

const SIGN_UP_TYPE = (data, callback) => {
  return {
    type: "SIGN_UP_TYPE",
    payload: data,
    callback,
  };
};

const GET_SIGNUP_OTP_VERIFY = (data, page, callback) => {
  return {
    type: "GET_SIGNUP_OTP_VERIFY",
    payload: data,
    page: page,
    callback,
  };
};
const GET_REGISTER_USER = (data, callback) => {
  return {
    type: "GET_REGISTER_USER",
    payload: data,
    callback,
  };
};

const GET_REGISTER_GUEST_USER = (data, callback) => {
  return {
    type: "GET_REGISTER_GUEST_USER",
    payload: data,
    callback,
  };
};

const GET_MASTER_LOGIN = (data, callback) => {
  return {
    type: "GET_MASTER_LOGIN",
    payload: data,
    callback,
  };
};

// ***************RESTAURANT SIGN-UP***********************
const GET_RESTAURENT_SIGNUP = (data, callback) => {
  return {
    type: "GET_RESTAURENT_SIGNUP",
    payload: data,
    callback,
  };
};

// ***************DRIVER SIGN-UP***********************
const GET_CITY_DATA = (data, callback) => {
  return {
    type: "GET_CITY_DATA",
    payload: data,
    callback,
  };
};
const SET_CITY_DATA = (data) => {
  return {
    type: "SET_CITY_DATA",
    payload: data,
  };
};

const GET_DRIVER_SIGN_UP = (data, callback) => {
  return {
    type: "GET_DRIVER_SIGN_UP",
    payload: data,
    callback,
  };
};

const SET_ACCOUNT_MODAL = (data) => {
  return {
    type: "SET_ACCOUNT_MODAL",
    payload: data,
  };
};

const SET_ADDRESS = (data) => {
  return {
    type: "SET_ADDRESS",
    payload: data,
  };
};
const GET_LOGOUT = (data, callback) => {
  return {
    type: "GET_LOGOUT",
    payload: data,
    callback,
  };
};

const DELETE_ACCOUNT = (data, callback) => {
  return {
    type: "DELETE_ACCOUNT",
    payload: data,
    callback,
  };
};

const LOGOUT = (data) => {
  return {
    type: "LOGOUT",
    payload: data,
  };
};
const GET_CHANGE_PASSWORD = (data, callback) => {
  return {
    type: "GET_CHANGE_PASSWORD",
    payload: data,
    callback,
  };
};
const GET_CHECK_PROVIDER_ALREADY_EXIST = (data, callback) => {
  return {
    type: "GET_CHECK_PROVIDER_ALREADY_EXIST",
    payload: data,
    callback,
  };
};
const GET_SOCIAL_LOGIN_DETAILS = (data) => {
  return {
    type: "GET_SOCIAL_LOGIN_DETAILS",
    payload: data,
  };
};

const GET_SOCIAL_LOGIN = (data, callback) => {
  return {
    type: "GET_SOCIAL_LOGIN",
    payload: data,
    callback,
  };
};
const GET_DATE_TIME_ON_MENU = (data) => {
  return {
    type: "GET_DATE_TIME_ON_MENU",
    payload: data,
  };
};

export {
  GET_LOGIN,
  SET_LOGIN,
  GET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD,
  GET_OTP_VERIFY,
  SET_OTP_VERIFY,
  GET_RESET_PASSWORD,
  GET_GENERATE_OTP,
  GET_RESTAURENT_SIGNUP,
  GET_CITY_DATA,
  SET_CITY_DATA,
  GET_DRIVER_SIGN_UP,
  GET_USER_VERIFY,
  GET_SIGN_UP_DETAILS,
  GET_SIGNUP_OTP_VERIFY,
  GET_REGISTER_USER,
  SET_ACCOUNT_MODAL,
  SET_ADDRESS,
  GET_LOGOUT,
  GET_CHANGE_PASSWORD,
  GET_CHECK_PROVIDER_ALREADY_EXIST,
  GET_SOCIAL_LOGIN_DETAILS,
  GET_SOCIAL_LOGIN,
  GET_DATE_TIME_ON_MENU,
  GET_MASTER_LOGIN,
  DELETE_ACCOUNT,
  GET_REGISTER_GUEST_USER,
  SIGN_UP_TYPE,
};
